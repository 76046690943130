// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import "./forgot.scss";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import httpRequest from "../../../Utils/httpRequest";
import Loader from "../../../Components/Preloader";
import { updateToasterConfig } from "./../../../../src/redux/modules/app/actions";
import SendOTP from "./sendOtp";
import VerifyOTP from "./verifyOtp";
import NewPassword from "./newPassword";

//@component
//ForgotPassword
function ForgotPassword(props) {
  const [sendOtpFlag, setSendOtpFlag] = useState(true);
  const [verifyOtpFlag, setVerifyOtpFlag] = useState(false);
  const [newPwdFlag, setNewPwdFlag] = useState(false);

  const [username, setUsername] = useState("");
  const [otp, setOtp] = useState(null);
  const [newPwd, setNewPwd] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  //alert toaster function
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //function for alert toaster
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  // function api for change password
  const changePassword = () => {
    setIsLoading(true);
    httpRequest.postPublic(
      "forgotPass",
      {
        realmName:(window.location.host === "localhost:3000" ||
        window.location.host === "dev-admin.parkzeus.in")?"Rlm_ROBOIN_Parking_Admin_DEV_IN":"Rlm_ROBOIN_Parking_Admin_IN",
        changePasswordRequest:
        
        { emailId: username,
        otp: otp,
        newPassword: newPwd}
      },
      {
        "Content-Type": "application/json",
      },
      successCallbackChangePwd,
      errorCallbackChangePwd,
      {}
    );
  };
  // function for success change password
  const successCallbackChangePwd = (jsonResponse) => {
    if (jsonResponse) {
      setTimeout(() => {
        props.history.push("/login");
      }, 4000);
      setIsLoading(false);
      setOpenAlert(true);
      setSeverity("Success");
      setAlertMessage("Changed password successfully");
    }
  };
  // function for error change password
  const errorCallbackChangePwd = (error) => {
    setIsLoading(false);
    if (error) {
      setIsLoading(false);
      setOpenAlert(true);
      setSeverity("Error");
      setAlertMessage(error.message);
    }
  };
  // function api for get OTP
  const getOTP = () => {
    setIsLoading(true);
    httpRequest.postPublic(
      "getOTP",
       {
        realmName:(window.location.host === "localhost:3000" ||
        window.location.host === "dev-admin.parkzeus.in")?"Rlm_ROBOIN_Parking_Admin_DEV_IN":"Rlm_ROBOIN_Parking_Admin_IN",
        sendEmailOtpRequest: {
          emailId:username
        }
      },

      {
        "Content-Type": "application/json",
      },
      successCallbackGetOTP,
      errorCallbackGetOTP,
      {}
    );
  };
  // function for success get OTP
  const successCallbackGetOTP = (jsonResponse) => {
    console.log(jsonResponse);
    if (jsonResponse) {
      setOpenAlert(true);
      setSeverity("Success");
      setAlertMessage("OTP sent to Email successfully");
      setIsLoading(false);
      setSendOtpFlag(false);
      setVerifyOtpFlag(true);
      setNewPwdFlag(false);
    }
  };
  // function for error get OTP
  const errorCallbackGetOTP = (error) => {
    console.log(error);
    setIsLoading(false);
    if (error) {
      setOpenAlert(true);
      setSeverity("Error");
      setAlertMessage(error.message);
    }
  };
  // function to handle sendOTP button
  const handleSendOTP = () => {
    getOTP();
  };
  // function to handle cancel verifyOTP button
  const handleCancelVerifyOTP = () => {
    setSendOtpFlag(true);
    setVerifyOtpFlag(false);
    setNewPwdFlag(false);
  };
  // function to handle verifyOTP button
  const handleVerifyOTP = () => {
    setSendOtpFlag(false);
    setVerifyOtpFlag(false);
    setNewPwdFlag(true);
  };
  // function to handle password change cancel button
  const handlePwdChangeCancel = () => {
    setSendOtpFlag(false);
    setVerifyOtpFlag(true);
    setNewPwdFlag(false);
    // console.log("parent component:",username+" and "+otp)
  };
  // function to handle change password button
  const handleChangePwd = () => {
    changePassword();
  };
  // function to set username callback
  const handleSetUsername = (data) => {
    // console.log("parent componet setter: ",data)
    setUsername(data);
  };
  // function to set OTP callback
  const handleSetOTP = (data) => {
    setOtp(data);
  };
  // function to set new pwd callback
  const handleSetNewPwd = (data) => {
    setNewPwd(data);
  };
  // function to set confirm pwd callback
  const handleSetConfirmPwd = (data) => {
    if (data) {
      // any statement??
    }
    // setConfirmPwd(data)
  };
  // component mount/update lifecycle hook method
  useEffect(() => {
    // console.log("parent component: ",username)
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {sendOtpFlag ? (
        <SendOTP
          handleOTPToggle={handleSendOTP}
          setUsername={handleSetUsername}
        />
      ) : null}
      {verifyOtpFlag ? (
        <VerifyOTP
          cancelOTPToggle={handleCancelVerifyOTP}
          verifyOTPToggle={handleVerifyOTP}
          setUsername={handleSetUsername}
          setOTP={handleSetOTP}
        />
      ) : null}
      {newPwdFlag ? (
        <NewPassword
          cancelPwdToggle={handlePwdChangeCancel}
          pwdChangeToggle={handleChangePwd}
          setNewPwd={handleSetNewPwd}
          setConfirmPwd={handleSetConfirmPwd}
        />
      ) : null}
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={severity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  dispatch,
});
//method to connect react with redux
export default withRouter(connect(null, mapDispatchToProps)(ForgotPassword));
