// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import axios from "axios";
import TokenStorage from "./tokenstorage";
import { getRefreshToken } from "./refreshToken";
import UrlConfig from "../Constants/apiUrls";
import { getCookie, setCookie } from "./cookies";
import  configureStore  from '../redux/store/index'


var store = configureStore();

 const axiosInstance = axios.create();
//intercept request and responses to validate token, additional checks, add headers etc
axiosInstance.interceptors.request.use(
  async function(request) {
    //return request without validating token for these public api's
    if (
      request.url.includes("/forgetPassword") ||
      request.url.includes("/sendEmailOtp") ||
      request.url.includes("/config") ||
      request.url.includes("/otpChangePassword")
    ) {
      return request;
    }
    //intercept if private api has token which expired , get token using refresh and return the request .
    if (!request.url.includes("login")) {
            if (new Date(TokenStorage.getTokenExpirationTime()) <= new Date()) 
        {
          if(getCookie('inactive')==="false"){
          await getRefreshToken();   
          request.headers["Authorization"] = "bearer " + TokenStorage.getToken();
          return request;
          }
        } 
           else 
      {
        return request;
      }
    } 
    else {
      return request
    }
  },

  function(error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function(response) {
    return response;
  },

  async function(error) {
    // catching cors/network issues
    if (error.toString() == "Error: Network Error" && getCookie("auth")) {
      console.log("Network error / Cors issue", error);
    }
    //Catching unauthenticated internal pages access without login - reroute to login page
    if (
      error.toString() == "Error: Network Error" &&
      getCookie("auth") === "" &&
      getCookie("refresh") === "" &&
      window.location.pathname !== "/login"
    ) {
      console.log("Network error - No Auth ", error);
      TokenStorage.clear();
      window.location.href = "/login";
    }
    //upon unauthorized access (401 )check for refresh token upon success call original request again with the token
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      error.response.data.error_description !== "Account is locked" &&
      error.response.data.error_description !== "Invalid user credentials"
    ) {
      originalRequest._retry = true;
      return axios
        .post(
          UrlConfig("loginUrl"),
          "refresh_token=" +
            TokenStorage.getRefreshToken() +
            "&grant_type=refresh_token&client_id=" +
            getCookie("cliId") +
            "&client_secret=" +
            getCookie("cliSec"),
          { "content-type": "application/x-www-form-urlencoded" }
        )
        .then(async (response) => {
          TokenStorage.storeToken(await response.data.access_token);
          TokenStorage.storeRefreshToken(response.data.refresh_token);
          setCookie("inactive",false)
          let dt = new Date();
          dt.setSeconds(dt.getSeconds() + (response.data.expires_in - 60));
          TokenStorage.setTokenExpirationTime(dt);
          return await axiosInstance(originalRequest);

        })
        .catch((error) => {
          console.log("error refreshing token", error.response.data.error_description);
          if (error.response.status === 400) {
           if (error.response.data.error_description === "User disabled") {    
            setCookie("inactive",true)
          // store.dispatch(updateShowModal(true))
             }
             else if (error.response.data.error_description === "Invalid refresh token"){
              TokenStorage.clear();
              window.location.href = "/login";
             } 
            }
            else if (error.response.status === 401 && error.response.data.error === 'unauthorized'  )
            {
              TokenStorage.clear();
              window.location.href = "/login";
           }
        });
      // }
    }

    return Promise.reject(error);
    
  }
);

export default axiosInstance



