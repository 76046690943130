// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent
} from '@material-ui/core/';
import Lens from '@material-ui/icons/Lens';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import StoreContext from '../../../context/dashboard-context';
import httpRequest from '../../../Utils/httpRequest';
import verified from '../../../Assets/Dashboard_icons/Verified_tag/Group 4958@2x.png';
import live from '../../../Assets/Dashboard_icons/Legends_icons/live and open status.png'
import outside from '../../../Assets/Dashboard_icons/Legends_icons/outside operating hrs status.png'
import offline from '../../../Assets/Images/offline.png';
import bike from '../../../Assets/Dashboard_icons/Bike_icons/motorcycle.png';
import car from '../../../Assets/Dashboard_icons/Car_icons/car.png';
import suv from '../../../Assets/Dashboard_icons/Car_icons/SUV.png'
import amber from '../../../Assets/Dashboard_icons/Legends_icons/alert-warning-filled.png';
import red from '../../../Assets/Dashboard_icons/Legends_icons/alert-error-filled.png';
import OvernightViolation from '../../../Assets/Dashboard_icons/Card_icons/Enable/Group 4936@2x.png';
import OvernightRequest from '../../../Assets/Dashboard_icons/Card_icons/Enable/Group 4923@2x.png';
import Device from '../../../Assets/Dashboard_icons/Card_icons/Enable/Group 4866@2x.png';
import OvernightViolationGray from '../../../Assets/Dashboard_icons/Card_icons/Grayish/Group 4936@2x.png';
import OvernightRequestGray from '../../../Assets/Dashboard_icons/Card_icons/Grayish/Group 4923@2x.png';
import DeviceGray from '../../../Assets/Dashboard_icons/Card_icons/Grayish/Group 4866@2x.png';
import AnprCar from '../../../Assets/Dashboard_icons/Card_icons/Enable/ANPR_car@2x.png';
import AnprBike from '../../../Assets/Dashboard_icons/Card_icons/Enable/ANPR_bike@2x.png';
import AnprGray from '../../../Assets/Dashboard_icons/Card_icons/Grayish/Group 5140@2x.png';
import './ViewContainer.scss';
import { getCookie } from '../../../Utils/cookies';

const useStyles = makeStyles({
  root: {
    "& .MuiSvgIcon-root": {
      height: '0.75rem',
      width: '0.4rem',
      color:'#03A9F3',
    }
  },
});

//@component
//Card View
const DashboardOverview = () => {
  const classes = useStyles();
  const [parkingData, setparkingData] = useState([]);
  const [prevDate, setprevDate] = useState(null);
  const [vehicleType, setVehicleType] = useState('CAR');

  //function to toggle ANPR 4w and 2w
  const updateAnpr = () => {
    if (vehicleType == 'CAR') {
      setVehicleType('BIKE')
    }
    else {
      setVehicleType('CAR')
    }
  }

  const handleChange =(e) =>{
    setVehicleType(e.target.value)
  }

  //upon succesfully getting dashboard data 
  const updateSuccess = (jsonResult) => {
    let parkData = jsonResult.data;
    parkData.sort((a) => (a.isDeviceCoupled ? -1 : 1));
    setparkingData(parkData);
    //  updateAnpr();

  };
  const errorCallback = (error) => {
    if (error) {
      // setdashboardError("Could not fetch data... try again!")
    }
  }

  //function for checkOpen
  const checkOpen = () => {
   // var timings = parkingData[index]?.operatingHoursInfo[dayArray[curDay]];
    //if not 24 hrs open
    // if (
    //   timings &&
    //   curTime > timings[0].openingTime &&
    //   curTime < timings[0].closingTime
    // ) {
    //   return live;
    // } else {
    //   return outside;
    // }
    return live;
  }
  //upon component load or unload
  useEffect(() => {
    httpRequest.get(
      "parkingListDashboardAsc",
      {},
      updateSuccess,
      errorCallback,
      {}
    );
    let date = new Date();
    let prevdate = date.setDate(date.getDate() - 1);
    setprevDate(prevdate);
    // updateme();
    const updatemeInterval = setInterval(() => {
      httpRequest.get(
        "parkingListDashboardAsc",
        {},
        updateSuccess,
        errorCallback,
        {

        }
      );
    }, 30000);
    let anprInterval = setInterval(() => {
      updateAnpr();
    }, 5000)
    return () => {
      clearInterval(anprInterval);
      clearInterval(updatemeInterval);
    }
  }, [vehicleType])
  return (
    <StoreContext.Consumer>{context => (
      <ClickAwayListener onClickAway={context.handleClickAway}>
        <Grid container className="parent" onClick={context.clickBlank} style={{ textAlign: '-webkit-center' }}>
          {parkingData.map((item, index) => (
            <Grid key={index} item xs={12}>
              <Card className="cust-card" onDoubleClick={() => { context.cardClicked(item.parkingId); }}>
                <Grid container direction="row" xs={12} style={{ margin: '1.2% 0% 0% 1.5%', alignItems: 'center' }}>
                  <Grid item container xs={10} >
                    <label style={{
                      fontSize: '1vw',
                      fontWeight: 'bolder',
                      color: '#102E35'
                    }}>
                      {item.name.length > 50
                        ? `${item.name.slice(0, 50)}... `
                        : item.name}
                    </label>
                    <span className="liveTick">
                      {item.status.name === 'VERIFIED' ? (
                        <img src={checkOpen(index)} style={{ height: '65%' }} alt="image2" />
                      ) : item.status.name ===
                        'REQUEST_SUBMITTED' ? (
                        <img src={outside} alt="image2" />
                      ) : item.status.name === 'APPROVED' ? (
                        <img src={checkOpen(index)} alt="image2" />
                      ) : item.status.name ===
                        'REQUEST_REJECTED' ? (
                        <img src={offline} alt="image2" />
                      ) : (
                        <img src={offline} alt="image2" />
                      )}
                    </span>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'center' }}>
                    {item.isVerified ? (
                      <img
                        className="verified-image"
                        src={verified}
                        alt="image4"
                      />
                    ) : (
                      <br />
                    )}
                  </Grid>
                </Grid>
                <CardContent>
                  <Grid container direction="row" xs={12} spacing={1} style={{
                    display: 'grid',
                    gridTemplateColumns: '1.2fr 1fr 1fr 1fr 1fr',
                    gridTemplateRows: 'auto',
                  }} >
                    <Grid item className="top-card "  >
                      <Card raised={true} className="cardSizeSlot" >
                        <div ></div>
                        <div className="labelSlot">Total</div>
                        <div className="labelSlot">Occupied</div>
                        <div className="labelSlot">Vacant</div>
                        <div className="item2"><img src={item?.isVehicleSubTypeEnabled==false?bike:suv} title={item?.isVehicleSubTypeEnabled==true?"SUV":""} alt="2W"></img></div>
                        {item?.isVehicleSubTypeEnabled==false?(<>
                          <div className="totalSlot">{
                          //item?.vehicleTypeInfo[1]?.totalSlots?item?.vehicleTypeInfo[1]?.totalSlots:'-'
                          item?.vehicleTypeInfo?.length == 1 && item?.vehicleTypeInfo[0].vehicleType?.uid == '2'

                            ? item?.vehicleTypeInfo[0]?.totalSlots
                            : (item?.vehicleTypeInfo?.length == 0 || item?.vehicleTypeInfo?.length == 1) && item?.vehicleTypeInfo.filter(x => x.vehicleType?.uid != '2')

                              ? '-'
                              : item?.vehicleTypeInfo[1]?.totalSlots
                        }</div>
                        <div className="occSlot">{
                            item?.vehicleTypeInfo?.length == 1 && item?.vehicleTypeInfo[0].vehicleType?.uid == '2' && !item?.isGatewayOutOfSync
                            ? item?.vehicleTypeInfo[0]?.slotUtilization?.occupiedSlots
                            : (item?.vehicleTypeInfo?.length == 0 || item?.vehicleTypeInfo?.length == 1) && item?.vehicleTypeInfo.filter(x => x.vehicleType?.uid != '2' && item?.isGatewayOutOfSync)

                              ? '-'
                              : item?.isGatewayOutOfSync
                                ? '-'
                                : item?.vehicleTypeInfo[1]?.slotUtilization?.occupiedSlots
                        }</div>
                        <div className="vacSlot">{
                          //!item?.isGatewayOutOfSync ? item?.vehicleTypeInfo[1]?.slotUtilization?.vacantSlots : '-'
                          item?.vehicleTypeInfo?.length == 1 && item?.vehicleTypeInfo[0].vehicleType?.uid == '2' && !item?.isGatewayOutOfSync

                            ? item?.vehicleTypeInfo[0]?.slotUtilization?.vacantSlots
                            : (item?.vehicleTypeInfo?.length == 0 || item?.vehicleTypeInfo?.length == 1) && item?.vehicleTypeInfo.filter(x => x.vehicleType?.uid != '2' && item?.isGatewayOutOfSync)

                              ? '-'
                              : item?.isGatewayOutOfSync
                                ? '-'
                                : item?.vehicleTypeInfo[1]?.slotUtilization?.vacantSlots
                        }</div>
                        </>):(<>
                         <div className="totalSlot">{
                          item?.vehicleTypeInfo[0]?.vehicleType?.vehicleSubTypeSlotCount?.find((x)=>x.vehicleSubTypeName=="SUV")?item?.vehicleTypeInfo[0]?.vehicleType?.vehicleSubTypeSlotCount.filter((x)=>x.vehicleSubTypeName=="SUV")[0].totalSubVehicleCount:"-"
                          }</div>
                        <div className="occSlot">{
                          item?.vehicleTypeInfo[0]?.vehicleType?.vehicleSubTypeSlotCount?.find((x)=>x.vehicleSubTypeName=="SUV")?item?.vehicleTypeInfo[0]?.vehicleType?.vehicleSubTypeSlotCount.filter((x)=>x.vehicleSubTypeName=="SUV")[0].occupiedSlotCount:"-"
                          }</div>
                        <div className="vacSlot">{
                          item?.vehicleTypeInfo[0]?.vehicleType?.vehicleSubTypeSlotCount?.find((x)=>x.vehicleSubTypeName=="SUV")?item?.vehicleTypeInfo[0]?.vehicleType?.vehicleSubTypeSlotCount.filter((x)=>x.vehicleSubTypeName=="SUV")[0].availableSlotCount:"-"
                          }</div>
                         </>)}
                        <div className="item3"><img title={item?.isVehicleSubTypeEnabled==true?"SEDAN":""} src={car} alt="4W"></img></div>
                        {item?.isVehicleSubTypeEnabled==false?(<><div className="totalSlot">{
                          item?.vehicleTypeInfo.find(x => x.vehicleType.uid == '1') ? item?.vehicleTypeInfo[0]?.totalSlots : '-'
                        }</div>
                        <div className="occSlot">{!item?.isGatewayOutOfSync && item?.vehicleTypeInfo.find(x => x.vehicleType.uid == '1') ? item?.vehicleTypeInfo[0]?.slotUtilization?.occupiedSlots : '-'}</div>
                        <div className="vacSlot">{!item?.isGatewayOutOfSync && item?.vehicleTypeInfo.find(x => x.vehicleType.uid == '1') ? item?.vehicleTypeInfo[0]?.slotUtilization?.vacantSlots : '-'}</div>
                        </>):(<>  
                        <div className="totalSlot">{
                          item?.vehicleTypeInfo[0]?.vehicleType?.vehicleSubTypeSlotCount?.find((x)=>x.vehicleSubTypeName=="SEDAN")?item?.vehicleTypeInfo[0]?.vehicleType?.vehicleSubTypeSlotCount.filter((x)=>x.vehicleSubTypeName=="SEDAN")[0].totalSubVehicleCount:"-"
                          }</div>
                        <div className="occSlot">{
                          item?.vehicleTypeInfo[0]?.vehicleType?.vehicleSubTypeSlotCount?.find((x)=>x.vehicleSubTypeName=="SEDAN")?item?.vehicleTypeInfo[0]?.vehicleType?.vehicleSubTypeSlotCount.filter((x)=>x.vehicleSubTypeName=="SEDAN")[0].occupiedSlotCount:"-"
                          }</div>
                        <div className="vacSlot">{
                          item?.vehicleTypeInfo[0]?.vehicleType?.vehicleSubTypeSlotCount?.find((x)=>x.vehicleSubTypeName=="SEDAN")?item?.vehicleTypeInfo[0]?.vehicleType?.vehicleSubTypeSlotCount.filter((x)=>x.vehicleSubTypeName=="SEDAN")[0].availableSlotCount:"-"
                          }</div>
                        </>)}
                      </Card>
                    </Grid>
                    <Grid  item className="top-card " >
                      <Link to={{
                        pathname: item?.isDeviceCoupled ?
                          "/portal/unregistered_nonstd_report"
                          :
                          undefined,
                        state: {
                          anprReportClick: true,
                          parkingId: item?.parkingId,
                          parkingName: item?.name,
                        }
                      }}
                        style={{ textDecoration: 'none' }}
                      >
                        <Card className="card-size" raised={true}>
                          <Grid item xs={12} style={{ visibility: 'hidden', padding: '5px 5px 0 0', height: '1.2rem' }}>
                            <label>a</label>
                          </Grid>
                          <Grid className="cardSize">
                            <img className="imgCard" src={item?.isDeviceCoupled ?
                              vehicleType == 'CAR' ? AnprCar
                                : AnprBike
                              : AnprGray} alt="icon" />

                            <div className="mt-3">
                              <span className="dashCardvalue">{item?.isDeviceCoupled ? (
                                <Grid container>
                                  <Grid item >
                                    {vehicleType == 'CAR' ? item?.anprAccessCount4w!==0?Math.round((item?.anprAccessCount4w*100)/(item?.nonAnprAccessCount4w+item?.anprAccessCount4w)):item?.anprAccessCount4w : item?.anprAccessCount2w!==0?Math.round( (item?.anprAccessCount2w*100)/(item?.nonAnprAccessCount2w+item?.anprAccessCount2w)):item?.anprAccessCount2w}
                                    <span style={{fontSize: 'small'}}>%</span>
                                  </Grid>
                                  <Grid item style={{ paddingLeft: '1.4em' }}>
                                    {vehicleType == 'CAR' ? item?.nonAnprAccessCount4w!==0?Math.round((item?.nonAnprAccessCount4w*100)/(item?.nonAnprAccessCount4w+item?.anprAccessCount4w)):item?.nonAnprAccessCount4w: item?.nonAnprAccessCount2w!==0?Math.round( (item?.nonAnprAccessCount2w*100)/(item?.nonAnprAccessCount2w+item?.anprAccessCount2w)):item?.nonAnprAccessCount2w}
                                    <span style={{fontSize: 'small'}}>%</span>
                                  </Grid>
                                </Grid>
                              ) : <span style={{ color: '#707070' }}>-</span>}</span>
                              <p className="dashCardLabel">{item?.isDeviceCoupled ? (
                                <Grid container>
                                  <Grid item >
                                    ANPR
                                  </Grid>
                                  <Grid item style={{ paddingLeft: '2em' }}>
                                    Non-ANPR
                                  </Grid>
                                </Grid>
                              ) : <span style={{ color: '#707070' }}>ANPR Access Count</span>}</p>
                              {item?.isDeviceCoupled && (
                              <RadioGroup row name="anpr" value={null} style={{ marginLeft: '17%', height: 0 }} >
                                <Radio
                                  checked={vehicleType == 'CAR'}
                                  onClick={handleChange}
                                  checkedIcon={<Lens></Lens>}
                                  value="CAR"
                                  name="4w"
                                  size="small"
                                  inputProps={{ 'aria-label': 'CAR' }}
                                  style={{ height: '1.3rem', width:0}}
                                  className={classes.root}
                                />
                                <Radio
                                  checked={vehicleType == 'BIKE'}
                                  onChange={handleChange}
                                  checkedIcon={<Lens></Lens>}
                                  value="BIKE"
                                  name="2w"
                                  size="small"
                                  inputProps={{ 'aria-label': 'BIKE' }}
                                  style={{ height: '1.3rem', width:0}}
                                  className={classes.root}
                                />
                              </RadioGroup>)
                              }
                            </div>

                          </Grid>

                        </Card>
                      </Link>


                    </Grid>
                    <Grid  item className="top-card " >
                      <Link to={{
                        pathname: item?.isDeviceCoupled ?
                          "/portal/overnight_violation_report"
                          :
                          undefined,
                        state: {
                          onpReportClick: true,
                          parkingId: item?.parkingId,
                          parkingName: item?.name,
                          prevDate: prevDate
                        }
                      }}
                        style={{ textDecoration: 'none' }}
                      >
                        <Card className="card-size" raised={true}  >
                          <Grid item xs={12} style={{ visibility: 'hidden', padding: '5px 5px 0 0', height: '1.2rem' }}>
                            <label>a</label>
                          </Grid>
                          <Grid className="cardSize">
                            <img className="imgCard" src={item?.isDeviceCoupled ? OvernightViolation : OvernightViolationGray} alt="icon" />
                            <div className="mt-3">
                              <span className="dashCardvalue">{item?.isDeviceCoupled ? item?.overnightViolationCount : <span style={{ color: 'gray' }}>-</span>}</span>
                              <p className="dashCardLabel">{item?.isDeviceCoupled ? 'Overnight Violations' : <span style={{ color: 'gray' }}>Overnight Violations</span>}</p>
                            </div>
                          </Grid>
                        </Card>
                      </Link>
                    </Grid>
                    <Grid  item className="top-card" >
                      <Link to={{
                        pathname: item?.isDeviceCoupled && item?.createdBy===getCookie('userId')?
                          "/portal/onplistviewdashboard/"
                          :
                          undefined,
                        state: {
                          onpRequest: true,
                          parkingId: item?.parkingId,
                          parkingName: item?.name
                        }
                      }}
                        style={{ textDecoration: 'none',
                              cursor:item?.createdBy===getCookie('userId')?'pointer':'default'
                      }}
                      >
                        <Card className="card-size" raised={true} >
                          {(item?.overnightParkingRequestPendingCount > 0 && item?.createdBy===getCookie('userId')) ?
                            (
                              <Grid item xs={12} style={{ textAlign: 'right', padding: '5px 5px 0 0', height: '1.2rem' }}>
                                <img src={amber} alt="warning" style={{ width: '1.25vw', height: '2.4vh' }} />
                              </Grid>
                            )
                            : (
                              <Grid item xs={12} style={{ visibility: 'hidden', textAlign: 'right', padding: '5px 5px 0 0', height: '1.2rem' }}>
                                <img src={amber} alt="warning" style={{ width: '0.9rem' }} />
                              </Grid>
                            )
                          }
                          <Grid className="cardSize">
                            <img className="imgCard" src={(item?.isDeviceCoupled && item?.createdBy===getCookie('userId')) ? OvernightRequest : OvernightRequestGray} alt="icon" />
                            <div className="mt-3">
                              <span className="dashCardvalue">{(item?.isDeviceCoupled && item?.createdBy===getCookie('userId')) ? item?.overnightParkingRequestCount : <span style={{ color: 'gray' }}>-</span>}</span>
                              <p className="dashCardLabel">{(item?.isDeviceCoupled && item?.createdBy===getCookie('userId')) ? 'Overnight Requests' : <span style={{ color: 'gray' }}>Overnight Requests</span>}</p>
                            </div>
                          </Grid>
                        </Card>
                      </Link>
                    </Grid>
                    <Grid item
                      className="top-card"
                      
                    >
                      <Link
                        to={
                          {
                            pathname: (item?.deviceHealthStatus.filter(dev => { return dev.deviceType === 'gw' })[0] === undefined) ?
                              undefined
                              :
                              "/portal/parking-details/" + item?.parkingId,
                            state: {
                              deviceClick: (item?.deviceHealthStatus.filter(dev => { return dev.deviceType === 'gw' })[0] === undefined) ?
                                undefined
                                :
                                true
                            }
                          }
                        }
                        style={{ textDecoration: 'none' }}
                      >
                        <Card raised={true} className="card-size" >
                          {item?.isDeviceCoupled ?
                            (item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'gw' })[0]?.status?.name === 'UP' &&
                              (item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'anpr' })[0]?.status?.name === 'UP' &&
                                item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'ioc' })[0]?.status?.name === 'UP' &&
                                item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'boom' })[0]?.status?.name === 'UP' &&
                                item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'vms' })[0]?.status?.name === 'UP' &&
                                item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'zcu' })[0]?.status?.name === 'UP' &&
                                ((item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'epld' }).length!==0)?(item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'epld' })[0]?.status?.name === 'UP'):true))
                              ? <>
                                <Grid item xs={12} style={{ visibility: 'hidden', textAlign: 'right', padding: '5px 5px 0 0', height: '1.2rem' }}>
                                  <img src={red} alt="warning" style={{ width: '1.25vw', height: '2.4vh' }} />
                                </Grid> </>
                              : (item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'gw' })[0]?.status?.name === 'UP') &&
                                (item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'anpr' })[0]?.status?.name === 'DOWN' ||
                                  item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'ioc' })[0]?.status?.name === 'DOWN' ||
                                  item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'boom' })[0]?.status?.name === 'DOWN' ||
                                  item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'vms' })[0]?.status?.name === 'DOWN' ||
                                  item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'zcu' })[0]?.status?.name === 'DOWN' ||
                                  ((item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'epld' }).length!==0)?(item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'epld' })[0]?.status?.name === 'DOWN'):true))
                                ?
                                <>
                                  <Grid item xs={12} style={{ textAlign: 'right', padding: '5px 5px 0 0', height: '1.2rem' }}>
                                    <img src={amber} alt="warning" style={{ width: '1.25vw', height: '2.4vh' }} />
                                  </Grid>
                                </>
                                :
                                <>
                                  <Grid item xs={12} style={{ textAlign: 'right', padding: '5px 5px 0 0', height: '1.2rem' }}>
                                    <img src={red} alt="warning" style={{ width: '1.25vw', height: '2.4vh' }} />
                                  </Grid>
                                </>
                            ) : (
                              <>
                                <Grid item xs={12} style={{ textAlign: 'right', padding: '5px 5px 0 0', height: '1.2rem' }}>
                                  <img src={offline} alt="warning" style={{ width: '1.25vw', height: '2.4vh' }} />
                                </Grid>
                              </>
                            )
                          }
                          <Grid
                            className="cardSize"
                          >
                            <img className="imgCardDev" src={item?.isDeviceCoupled ? Device : DeviceGray} alt="icon" />
                            <div className="mt-3">
                              
                              {
                                item?.isDeviceCoupled
                                  ? (item?.deviceHealthStatus.filter(dev => { return dev.deviceType === 'gw' })[0] === undefined) || (item?.deviceHealthStatus.filter(dev => { return dev.deviceType === 'gw' })[0]?.status?.name === 'DOWN')
                                    ? (<p className="devStatusOff">Offline</p>)
                                    : (<p className="devStatus">Online</p>)
                                  : (<p className="dashCardLabelDev" style={{marginTop: '0.5vw'}}>Uncommissioned</p>)
                              } 
                              <span className="devLabel">{item?.isDeviceCoupled ? 'Device Status' : <span style={{ color: '#707070' }}>Device Status</span>}</span>
                            </div>
                          </Grid>
                        </Card>
                      </Link>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </ClickAwayListener>
    )}
    </StoreContext.Consumer>
  );
}

//function for redux connect
export default DashboardOverview;

