// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import MuiDialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { Scrollbars } from "react-custom-scrollbars";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import bulkUploadUseStyles from "./style";
import FileUploader from "../../../Components/FileUploader/FileUploader";
import httpRequest from "../../../Utils/httpRequest";
import Loader from "../../../Components/Preloader";
import Template from "../../../Assets/pdf/Access_Table_Template.xlsx";
import Excel from "../../../Assets/icons/excel.png";
import { withStyles } from "@material-ui/core";

//@component
//BulkUpload
const BulkUpload = (props) => {
  const classes = bulkUploadUseStyles();
  const theme = useTheme();

  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [addFiles, setAddFiles] = useState([]);
  const [removeFiles] = useState([]);
  const [responseData, setResponseData] = useState("");
  const [open, setOpen] = useState(false);
  const [responseType, setResponseType] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [parkingSpaceList, setParkingSpaceList] = useState([{}]);
  const [addr, setAddr] = useState("");
  const [parkingId, setparkingId] = useState("");

  //mui custom styling
  const DialogActions = withStyles(() => ({
    root: {
      padding: "8px 0px !important",
      alignSelf: "center",
    },
  }))(MuiDialogActions);

  const DialogContentSuccess = withStyles(() => ({
    root: {
      overflowY: "hidden !important",
      paddingLeft: "0 !important",
    },
  }))(MuiDialogContent);

  //function to add files to state array after file selection
  function addFileHandler(files) {
    setAddFiles(files);
  }
  //function for image remove
  const removeFile = (files, props) => {
    if (files.file === undefined) {
      removeFiles.push(files);
    }

    // props.updateChildrenState(
    // 'step1',
    // 'deleteImagesFromSpace',
    // deleteImageList,
    // );
  };

  //api call to generate reports
  const bulkUploadHandler = () => {
    const formData = new FormData();
    addFiles.forEach((file) => {
      formData.append("file", file);
    });
    setIsLoading(true);
    httpRequest.post(
      "tempAccessBulkUpload",
      formData,
      {
        "Content-Type": "multipart/form-data",
      },
      successCallback,
      errorCallback,
      {
        parkingId: parkingId,
      }
    );
  };
  //success callback for report
  const successCallback = useCallback((jsonResponse) => {
    if (jsonResponse) {
      setIsLoading(false);
      setOpen(true);
      setResponseType("Success");
      setResponseData(
        jsonResponse.data.numOfUserVehicleUploded +
          " user Vehicles details uploaded successfully. We will sync the vehicle data with parking space gateway automatically."
      );
      setDialogTitle("Bulk Upload Successful");
    }
  }, []);
  //error callback
  const errorCallback = async (error, status) => {
   
    if (error) {
      setIsLoading(false);
      setOpen(true);

      if (status===504){
        let str = "Data is processing in the background... Please visit the Access Control Management page after sometime to check the status of your data. "
        setResponseData(str)
        setResponseType("Success");
        setDialogTitle("Bulk Upload Processing");
      }

     else if (error.type === "ERROR" && (error.message !== "Something went wrong. Please try again." || status!==500)) {
      let parsed = await parseLines(error.message);
      setDialogTitle("Error validating data, Enter valid data and try again.");
      setResponseData(parsed);
      // console.log(parsed.length)
      if (parsed?.length < 1000) {
        setResponseType("ErrorValSmall");
      } else {
        setResponseType("ErrorVal");
      }  
      }
      else  if (error.message === "Please upload .xlsx or .csv file and try again.") {
        // any error message
        setIsLoading(false);
        setOpen(true);
        setResponseType("ErrorCsv");
        setResponseData(error.message || "");
        setDialogTitle("Invalid File format");
      }
      else 
      {
        setResponseData("the operation was not successful, Please retry again");
        setResponseType("ErrorDup");
        setDialogTitle("Server Error");
      }     
    }
  };

  //function to handler dialog close on success 
  const dialogCloseHandler = () => {
    setOpen(false);
    setAddFiles([]);
    setResponseData("")
    setResponseType("");
    setDialogTitle("");
  };

  //function to handler dialog close on other scenarios
  const dialogCloseOk = () => {
    setOpen(false);
    setResponseData("")
    setResponseType("");
    setDialogTitle("");
  }
  const getParkingList = () => {
    httpRequest.get(
      "devCoupledParkingList",
      {},
      parkingListSuccess,
      errorCallback,
      {}
    );
  };
  const parkingListSuccess = (jsonResponse) => {
     setParkingSpaceList(jsonResponse.data);
  };

  const handleChangeId = (e) => {
    // console.log(e.target.value)
    setparkingId(e.target.value);
    httpRequest.get("parkingdetail", {}, parkingDetailSuccess, errorCallback, {
      parkingId: e.target.value,
    });
  };
  const parkingDetailSuccess = (jsonResponse) => {
    let address = "";
    address =
      jsonResponse.data?.addressDetails?.addressLine1 +
      " " +
      (jsonResponse.data?.addressDetails?.addressLine2
        ? jsonResponse.data?.addressDetails?.addressLine2
        : "") +
      jsonResponse.data?.addressDetails?.city +
      " " +
      jsonResponse.data?.addressDetails?.pincode +
      " " +
      jsonResponse.data?.addressDetails?.state;
    // console.log("first",address)
    setAddr(address);
  };

  const parseLines = (value) => value.replace(/(\\n)/g, "\n");
  //adding component to breadcrumb
  useEffect(() => {
    props.dispatch(updateBreadcrumbs("tempaccessupload"));
    getParkingList();
  }, [addFiles]);

  return (
    <>
      {isLoading && <Loader />}
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
      >
        <Card className={classes.bulkUploadCard}>
          <Grid>
            <select
              onChange={handleChangeId}
              className={classes.selectListCard}
            >
              <option value="">Select Parking Space</option>
              {parkingSpaceList.map((list, index) => {
                return (
                  <option key={index} value={list.parkingId}>
                    {list.name}
                  </option>
                );
              })}
            </select>
          </Grid>
          <Grid>
            <p className={classes.addressDetails}> {addr}</p>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justify="center"
              className={classes.bulkUploadTopRow}
            ></Grid>
          </Grid>

          <Grid item>
            <Grid container direction="row" justify="center">
              <Grid
                item
                container
                direction="row"
                justify="center"
                className={classes.InputRow}
              >
                <FileUploader fileHandler={addFileHandler} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" style={{ marginLeft: "5em" }}>
              <Grid item>
                {addFiles.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div key={index}>
                        <span
                          // className={classes.DeleteFile}
                          style={{
                            padding: "1px 4px",
                            background: "#e46a76",
                            borderRadius: "50%",
                            color: "#fff",
                            cursor: "pointer",
                            position: "absolute",
                            marginLeft: "25%",
                          }}
                          onClick={() => {
                            var dataFile = addFiles.splice(index, 1);
                            removeFile(dataFile, props);
                          }}
                        >
                          X
                        </span>
                        <img src={Excel} alt={item.name} />

                        <p>{item.name}</p>
                      </div>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" className={classes.ButtonRow}>
              <Grid
                item
                container
                justify="flex-end"
                style={{ marginBottom: "1rem" }}
              >
                <Button
                  disabled={addFiles.length === 0 || parkingId.length === 0}
                  onClick={bulkUploadHandler}
                  className={classes.fileUpload}
                  variant="contained"
                  style={{
                    marginBottom: matchesSM ? "2em" : undefined,
                    fontSize: "0.71rem",
                    color: "#fff",
                    backgroundColor: "#e46a76",
                    borderRadius: "20px",
                    width: "20em",
                  }}
                >
                  Upload
                </Button>
              </Grid>
              <Grid item container justify="flex-end">
                <a
                  style={{
                    textDecoration: "none",
                    fontSize: "1rem",
                    color: "#008ECF",
                  }}
                  // onClick={()=>window.location.href="C:\Users\DNP4KOR\Desktop\latest_template_17.xlsx"}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={Template}
                  download
                >
                  Download Sample Template
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <CardContent></CardContent>
        {responseType === "ErrorVal" && (
          <Dialog
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            PaperProps={{
              style: {
                padding: "2.25% 2.25% 1.5% 3.5%",
                width: "60%",
                height: "100%",
              },
            }}
            className={classes.dialog}
            fullWidth
            maxWidth="lg"
          >
            <MuiDialogTitle
              style={{
                paddingBottom: "8px",
                paddingTop: "2px",
                paddingLeft: "2px",
                paddingRight: "2px",
              }}
            >
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <span className={classes.dialogHeader}>{dialogTitle}</span>
                </Grid>
              </Grid>
            </MuiDialogTitle>
            <Divider />
            <MuiDialogContent
          className="dialog-content-grid"
          style={{ paddingLeft: "0px", paddingRight: "0px"}}
        >
          <Scrollbars >
          <TextField
                name="description"
                multiline
                required
                type="text"
                InputProps={{
                  className: classes.input,
                }}
                value={responseData}
              />
          </Scrollbars>
          </MuiDialogContent>
            <DialogActions>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  className={classes.dialogActionGrid}
                >
                  <Grid item className={classes.dialogCancelGrid}>
                    <button
                      type="button"
                      onClick={dialogCloseOk}
                      className={classes.dialogConfirm}
                    >
                      ok
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        )}
        {responseType === "ErrorValSmall" && (
          <Dialog
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            PaperProps={{
              style: {
                padding: "2.25% 2.25% 1.5% 3.5%",
                width: "60%",
                height: "60%",
                //maxHeight: "50%"
              },
            }}
            className={classes.dialog}
            fullWidth
            maxWidth="lg"
          >
            <MuiDialogTitle
              style={{
                paddingBottom: "8px",
                paddingTop: "2px",
                paddingLeft: "2px",
                paddingRight: "2px",
              }}
            >
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <span className={classes.dialogHeader}>{dialogTitle}</span>
                </Grid>
              </Grid>
            </MuiDialogTitle>
            <Divider />
            <MuiDialogContent
          className="dialog-content-grid"
          style={{ paddingLeft: "0px", paddingRight: "0px"}}
        >
          <Scrollbars >
          <TextField
                name="description"
                multiline
                required
                type="text"
                InputProps={{
                  className: classes.input,
                }}
                value={responseData}
              />
          </Scrollbars>
          </MuiDialogContent>
            <DialogActions>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  className={classes.dialogActionGrid}
                >
                  <Grid item className={classes.dialogCancelGrid}>
                    <button
                      type="button"
                      onClick={dialogCloseOk}
                      className={classes.dialogConfirm}
                    >
                      ok
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        )}

        {responseType === "ErrorDup" && (
          <Dialog
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            PaperProps={{
              style: {
                padding: "2.25% 2.25% 1.5% 2.5%",
                width: "100%",
                height: "30%",
              },
            }}
            className={classes.dialog}
          >
            <MuiDialogTitle
              style={{
                paddingBottom: "8px",
                paddingTop: "2px",
                paddingLeft: "2px",
                paddingRight: "2px",
              }}
            >
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <span className={classes.dialogHeader}>{dialogTitle}</span>
                </Grid>
              </Grid>
            </MuiDialogTitle>
            <Divider />
            <DialogContentSuccess>
              <Typography
                style={{
                  fontSize: "16px !important",
                  fontWeight: "bold",
                  color: "rgb(228, 106, 118)",
                }}
              >
                {responseData}
              </Typography>
            </DialogContentSuccess>
            <DialogActions>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  className={classes.dialogActionGrid}
                >
                  <Grid item className={classes.dialogCancelGrid}>
                    <button
                      type="button"
                      onClick={dialogCloseOk}
                      className={classes.dialogConfirm}
                    >
                      ok
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        )}
        {responseType === "Success" && (
          <Dialog
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            PaperProps={{
              style: {
                padding: "2.25% 2.25% 1.5% 2.5%",
                width: "100%",
                height: "70%",
              },
            }}
            className={classes.dialog}
          >
            <MuiDialogTitle
              style={{
                paddingBottom: "8px",
                paddingTop: "2px",
                paddingLeft: "2px",
                paddingRight: "2px",
              }}
            >
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <span className={classes.dialogHeader}>{dialogTitle}</span>
                </Grid>
              </Grid>
            </MuiDialogTitle>
            <Divider />
            <DialogContentSuccess>
              <Typography
                style={{
                  fontSize: "16px !important",
                  fontWeight: "bold",
                  color: "#67B561",
                }}
              >
                {responseData}
              </Typography>
            </DialogContentSuccess>
            <DialogActions>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  className={classes.dialogActionGrid}
                >
                  <Grid item className={classes.dialogCancelGrid}>
                    <button
                      type="button"
                      onClick={dialogCloseHandler}
                      className={classes.dialogConfirmSuccess}
                    >
                      ok
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        )}
      </Grid>
    </>
  );
};

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  dispatch,
});

//method to connect react with redux
export default withRouter(connect(null, mapDispatchToProps)(BulkUpload));
