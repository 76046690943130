// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core/";
import ImageUploader from "react-images-upload";
import "./../MainForm.scss";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Card } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import Map from "../../../../../Components/MapAddress";
import uploadImageService from "../../../../../Utils/uploadImageService";
import Loader from "../../../../../Components/Preloader";
import IconA from "../../../../../Assets/Images/IconA.png";
import IconB from "../../../../../Assets/Images/IconB.png";
import IconAA from "../../../../../Assets/Images/IconAA.png";
import IconBB from "../../../../../Assets/Images/IconBB.png";
import IconR from "../../../../../Assets/Images/IconR.png";
import IconRR from "../../../../../Assets/Images/IconRR.png";
import UploadIcon from "../../../../../Assets/Images/cloud-computing.svg";
import Tip from "../../../../../Assets/Images/info.svg";
import add from "../../../../../Assets/Images/add.png";
import closemap from "../../../../../Assets/Images/deletemap.png";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import OprationalTimmings from "./../OperationalTimings";
import TwoWheeler from "../../../../../Assets/Images/2 Wheeler.svg";
import FourWheeler from "../../../../../Assets/Images/4 Wheeler.svg";
import CarA from "../../../../../Assets/Images/carA.png";
import MotorA from "../../../../../Assets/Images/motorA.svg";
import error from "../../../../../Assets/Images/error.png";
import ServicesNearBy from "./../ServicesNearBy";
import ParkingFacilities from "./../ParkingFacilities";
import CustomAvailability from "./../CustomRule";
import httpRequest from "../../../../../Utils/httpRequest";
import { getCookie } from "../../../../../Utils/cookies";
import "./style.scss";

//Global variable declarations
var storeEditData = [];
var newData = [];
var deleteImageList = [];

//function for tabpanel
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
//function for tabpanel proptypes
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
//function for allyprops
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
//function for styles
const styles = {
  opacity: "0.5",
  pointerEvents: "none",
};
//function for activeFieldStyle
const activeFieldStyle = {
  opacity: "1",
  pointerEvents: "all",
};
//function scope array declaration tabs
const tabs = ["twoWheeler", "fourWheeler", "fleet"];
//function for updateParentState
const updateParentState = (parentKey, childKey, value, props) => {
  let obj = { ...storeEditData[parentKey] };
  obj[childKey] = value;
  if (parentKey === "parkingBusinessType") {
    props.updateChildrenState("step1", parentKey, obj);
  } else {
    props.updateChildrenState("step2", parentKey, obj);
  }
};
//function for changeTerms
const changeTerms = (parentKey, childKey, value, props) => {
  let obj = { ...storeEditData.step2[parentKey] };

  if (parentKey === "twoWheeler") {
    if (childKey === "pricePerDay") {
      obj.pricePerDay = value;
      obj.pricePerMonth = 0;
      obj.pricePerWeek = 0;
      props.updateChildrenState("step2", parentKey, obj);
    }
    if (childKey === "pricePerWeek") {
      obj.pricePerDay = 0;
      obj.pricePerMonth = 0;
      obj.pricePerWeek = value;
      props.updateChildrenState("step2", parentKey, obj);
    }
    if (childKey === "pricePerMonth") {
      obj.pricePerDay = 0;
      obj.pricePerMonth = value;
      obj.pricePerWeek = 0;
      props.updateChildrenState("step2", parentKey, obj);
    }
  }
  if (parentKey === "fourWheeler") {
    if (childKey === "pricePerDay") {
      obj.pricePerDay = value;
      obj.pricePerMonth = 0;
      obj.pricePerWeek = 0;
      props.updateChildrenState("step2", parentKey, obj);
    }
    if (childKey === "pricePerWeek") {
      obj.pricePerDay = 0;
      obj.pricePerMonth = 0;
      obj.pricePerWeek = value;
      props.updateChildrenState("step2", parentKey, obj);
    }
    if (childKey === "pricePerMonth") {
      obj.pricePerDay = 0;
      obj.pricePerMonth = value;
      obj.pricePerWeek = 0;
      props.updateChildrenState("step2", parentKey, obj);
    }
  }
  if (parentKey === "fleet") {
    if (childKey === "pricePerDay") {
      obj.pricePerDay = value;
      obj.pricePerMonth = 0;
      obj.pricePerWeek = 0;
      props.updateChildrenState("step2", parentKey, obj);
    }
    if (childKey === "pricePerWeek") {
      obj.pricePerDay = 0;
      obj.pricePerMonth = 0;
      obj.pricePerWeek = value;
      props.updateChildrenState("step2", parentKey, obj);
    }
    if (childKey === "pricePerMonth") {
      obj.pricePerDay = 0;
      obj.pricePerMonth = value;
      obj.pricePerWeek = 0;
      props.updateChildrenState("step2", parentKey, obj);
    }
  }
};

//function for updateRootState
const updateRootState = (key, value, props) => {
  props.updateChildrenState("step1", key, value);
};
//function for handleDragOver
const handleDragOver = (evt) => {
  evt.preventDefault();
  return false;
};
//function for handleDrop
const handleDrop = (evt, props) => {
  evt.preventDefault();
  evt.stopPropagation();
  if (evt.dataTransfer.files[0].size > 2084000) {
    document.getElementById("myDialog").showModal();
    setTimeout(() => {
      document.getElementById("myDialog").close();
    }, 3000);
  } else {
    let imagesData = evt.dataTransfer.files;
    uploadImageService.uploadImage(
      imagesData,
      uploadImageServiceCallBack,
      props
    );
  }
};
//function for removeImage
const removeImage = (imageData, props) => {
  if (imageData[0].file === undefined) {
    deleteImageList.push(imageData[0]);
    props.updateChildrenState(
      "step1",
      "deleteImagesFromSpace",
      deleteImageList
    );
  } else {
    deleteImageList.push(imageData[0]);
  }
  props.updateChildrenState("step1", "deleteImagesFromSpace", deleteImageList);
};
//function for uploadImageServiceCallBack
const uploadImageServiceCallBack = (imageData, props) => {
  if (props.data.step1.images.length >= 5) {
    return false;
  }
  newData = [imageData, ...props.data.step1.images];
  const result = [];
  const duplicatesIndices = [];
  newData.forEach((current, index) => {
    if (duplicatesIndices.includes(index)) return;
    result.push(current);
    for (
      let comparisonIndex = index + 1;
      comparisonIndex < newData.length;
      comparisonIndex++
    ) {
      const comparison = newData[comparisonIndex];
      const currentKeys = Object.keys(current);
      const comparisonKeys = Object.keys(comparison);

      if (currentKeys.length !== comparisonKeys.length) continue;
      const currentKeysString = currentKeys
        .sort()
        .join("")
        .toLowerCase();
      const comparisonKeysString = comparisonKeys
        .sort()
        .join("")
        .toLowerCase();
      if (currentKeysString !== comparisonKeysString) continue;
      let valuesEqual = true;
      for (let i = 0; i < currentKeys.length; i++) {
        const key = currentKeys[i];
        if (current[key] !== comparison[key]) {
          valuesEqual = false;
          break;
        }
      }
      if (valuesEqual) duplicatesIndices.push(comparisonIndex);
    }
  });
  props.updateChildrenState("step1", "images", result);
};

//@component
//EditParkingStepOne
function EditParkingStepOne(props) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [activeTab, setActiveTab] = useState("twoWheeler");
  const [showTheDiv, setTheDiv] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [slotFlag, setSlotFlag] = useState(false);
  const [slotPolicy, setSlotPolicy] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  props.updateRootState(0, 1);

  //function for onDrop
  const onDrop = (evt, props) => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    let imagesData = evt.slice(-5);
    if (imagesData.length === 0 || imagesData[0].size > 2084000) {
      document.getElementById("myDialog").showModal();
      setTimeout(() => {
        document.getElementById("myDialog").close();
      }, 3000);
    } else {
      uploadImageService.uploadImage(
        imagesData,
        uploadImageServiceCallBack,
        props
      );
    }
  };
  //function for handleChange
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //function for handleChangeIndex
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // function scope variable declaration definition
  const openSlot = Boolean(slotPolicy);
  const idSlot = openSlot ? "simple-popover" : undefined;
  //function for updateActiveTab
  const updateActiveTab = (tabName) => {
    setActiveTab(tabName);
  };
  //function for showCustomRule
  const showCustomRule = () => {
    setTheDiv(showTheDiv + 1);
  };
  props.updateRootState(1, 2);
  storeEditData = props.data;
  newData = [...props.data.step1.images];

  // function to handle i icon click event
  const handleClickSlot = (event) => {
    setSlotPolicy(event.currentTarget);
  };
  // function to handle i icon close event
  const handleCloseSlot = () => {
    setSlotPolicy(null);
  };

  // function to handle i icon click event
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // function to handle i icon close event
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    httpRequest.get(
      "userProfile",
      {},
      userDataSuccess,
      () => {
        console.log("error retrieving slots from user profile");
      },
      {
        userId: getCookie("userId"),
      }
    );

    if (props.data.step1.images) {
      setIsLoading(true);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  const userDataSuccess = (jsonResult) => {
    const { data } = jsonResult;

    if (!data.totalBikeSlots && !data.totalCarSlots) {
      setSlotFlag(false);
    } else {
      let slotFlag;
      slotFlag = data.totalBikeSlots !== 0 || data.totalCarSlots !== 0;
      setSlotFlag(slotFlag);
    }
  };

  const slotInputHandle = (event) => {
    // var inputBox = document.getElementById(event.target.id);
    // var invalidChars = [
    //   "-"
    // ];
    // inputBox.addEventListener("keydown", function(e) {
    //   if (invalidChars.includes(e.key)) {
    //     e.preventDefault();
    //   }

    // });
    // inputBox.addEventListener('paste', e => e.preventDefault());

    let data = {
      ...storeEditData.step2[activeTab],
    };

    data.totalSlots = parseInt(event.target.value);

    props.updateChildrenState("step2", activeTab, data);
  };

  return (
    <div className="contactdetails">
      <Card>
        <div className="contactdetails__wrapper">
          <div className="contactdetails__wrapper__heading">
            <p className="contactdetails__wrapper__heading__title">
              Parking Space and Contact Details
              {props.search !== "" ? (
                <span className="notedit">
                  <img src={error} alt="error" />
                  This Information change will be subjected to approval
                </span>
              ) : (
                ""
              )}
            </p>
            <span>
              <img
                alt="tip"
                src={Tip}
                style={{
                  marginBottom: "-3px",
                  marginTop: "10px",
                  marginLeft: "10px",
                  width: "15px",
                  display: "none",
                }}
              />
            </span>
          </div>
          <div className="contactdetails__wrapper__container">
            <div className="contactdetails__wrapper__container__leftside">
              <label>Parking Space Name*</label>

              <TextField
                fullWidth
                type="text"
                name="parkingName"
                inputProps={{ maxLength: 50 }}
                value={
                  storeEditData.step1.parkingName !== undefined
                    ? storeEditData.step1.parkingName
                    : props.data.step1.parkingName
                }
                onChange={(evt) => {
                  if (evt.target.value.length <= 100)
                    props.updateChildrenState(
                      "step1",
                      "parkingName",
                      evt.target.value
                    );
                }}
              />
              {storeEditData.step1.parkingName.length < 5 &&
              storeEditData.step1.parkingName.length > 0 ? (
                <p className="errorClass">Minimum 5 characters required</p>
              ) : (
                <p></p>
              )}
              <br />
              <label>Description*</label>
              <TextField
                className="descpBoxtext"
                fullWidth
                multiline
                placeholder="Enter Description of your parking space [500 characters max]"
                rows="5"
                type="textarea"
                name="description"
                value={storeEditData.step1.description}
                onChange={(evt) =>
                  props.updateChildrenState(
                    "step1",
                    "description",
                    evt.target.value
                  )
                }
              />
              <label>Contact Name*</label>
              <TextField
                fullWidth
                type="text"
                name="contactName"
                value={storeEditData.step1.contactName}
                inputProps={{ maxLength: 50 }}
                onChange={(evt) =>
                  props.updateChildrenState(
                    "step1",
                    "contactName",
                    evt.target.value
                  )
                }
              />
              <label>Contact Number*</label>
              <TextField
                fullWidth
                type="number"
                onWheel={(evt) => evt.target.blur()}
                className="phone_field"
                name="contactNumber"
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       <p className="num_prefix">+91</p>
                //     </InputAdornment>
                //   ),
                // }}
                value={storeEditData.step1.contactNumber}
                onChange={(evt) => {
                  if (
                    (evt.target.value === "" ||
                      /^[0-9\b]+$/.test(evt.target.value)) &&
                    evt.target.value.length <= 14
                  ) {
                    props.updateChildrenState(
                      "step1",
                      "contactNumber",
                      evt.target.value
                    );
                  }
                }}
              />
              <label>Contact Email*</label>
              <TextField
                fullWidth
                type="email"
                name="contactEmail"
                value={storeEditData.step1.contactEmail}
                inputProps={{ maxLength: 50 }}
                onChange={(evt) =>
                  props.updateChildrenState(
                    "step1",
                    "contactEmail",
                    evt.target.value
                  )
                }
              />
              <label>Parking Type*</label>
              <br />
              {(getCookie("role") === "MULTITENANT_ADMIN" ||
                getCookie("role") === "CLOSED_GROUP_PARKING_PROVIDER" ||
                getCookie("role") === "FACILITY_ADMIN") && (
                <>
                  <div
                    className={
                      props.data.step1.parkingBusinessType.uid == 1
                        ? "button__wrapper active"
                        : "button__wrapper"
                    }
                    onClick={() =>
                      updateParentState("parkingBusinessType", "uid", 1, props)
                    }
                  >
                    <div className="button__wrapper_left">
                      <img
                        src={
                          props.data.step1.parkingBusinessType.uid == 1
                            ? IconA
                            : IconB
                        }
                        alt="Icon"
                      ></img>
                    </div>
                    <div className="button__wrapper_right">
                      <p className="desc">Corporate Parking</p>
                      <p className="desc1">Companies, Tech Park, etc.</p>
                    </div>
                  </div>
                  <div
                    className={
                      props.data.step1.parkingBusinessType.uid == 2
                        ? "button__wrapper active"
                        : "button__wrapper"
                    }
                    onClick={() =>
                      updateParentState("parkingBusinessType", "uid", 2, props)
                    }
                  >
                    <div className="button__wrapper_left">
                      <img
                        src={
                          props.data.step1.parkingBusinessType.uid == 2
                            ? IconAA
                            : IconBB
                        }
                        alt="Icon"
                      ></img>
                    </div>
                    <div className="button__wrapper_right">
                      <p className="desc">Commercial Parking</p>
                      <p className="desc1">
                        Malls, Hospitals, Private parking, etc.
                      </p>
                    </div>
                  </div>
                </>
              )}
              {(getCookie("role") === "RESIDENTIAL_GROUP_PARKING_PROVIDER" ||
                getCookie("role") === "RESIDENTIAL_FACILITY_ADMIN") && (
                <>
                  <div
                    className={
                      props.data.step1.parkingBusinessType.uid == 3
                        ? "button__wrapper active"
                        : "button__wrapper"
                    }
                    onClick={() =>
                      updateParentState("parkingBusinessType", "uid", 3, props)
                    }
                  >
                    <div className="button__wrapper_left">
                      <img
                        src={
                          props.data.step1.parkingBusinessType.uid == 3
                            ? IconR
                            : IconRR
                        }
                        alt="Icon"
                      ></img>
                    </div>
                    <div className="button__wrapper_right">
                      <p className="desc">Residential Parking</p>
                      <p className="desc1">Apartments.</p>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="contactdetails__wrapper__container__rightside">
              <label>Add Parking Space Photos</label>
              <dialog id="myDialog">Image size is too big !!!</dialog>
              <div className="contactdetails__wrapper__container__rightside__box">
                <div
                  className="drag__and_drop"
                  onDragOver={(evt) => handleDragOver(evt)}
                  onDrop={(evt) => handleDrop(evt, props)}
                >
                  {isLoading && <Loader />}
                  <p className="contactdetails__wrapper__container__rightside__box_upimg">
                    <ImageUploader
                      withIcon={true}
                      buttonText="Choose images"
                      onChange={(evt) => onDrop(evt, props)}
                      imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                      singleImage={true}
                    ></ImageUploader>
                    <img
                      src={UploadIcon}
                      alt="UploadIcon"
                      className="UploadIcon"
                    />
                  </p>
                  <p className="contactdetails__wrapper__container__rightside__box_text">
                    Drag and Drop a file here or click
                  </p>
                  <p className="contactdetails__wrapper__container__rightside__box_text1">
                    You can upload upto 5 photos each should be less than 2mb
                  </p>
                </div>

                <div className="contactdetails__wrapper__container__rightside__box_imagesbox">
                  {newData.map((item, index) => {
                    return (
                      <div className="images" key={index}>
                        <img
                          src={
                            item.newImg
                              ? item.src
                              : `data:image/png;base64,${item}`
                          }
                          alt="imgSrc"
                        />

                        <span
                          className="delete-img"
                          onClick={() => {
                            var dataImage = newData.splice(index, 1);
                            props.updateChildrenState(
                              "step1",
                              "images",
                              newData
                            );
                            removeImage(dataImage, props);
                          }}
                        >
                          X
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Card>
        {/* <AddressDetails /> */}
        <div className="addressdetails">
          <div className="addressdetails__wrapper">
            <div className="addressdetails__wrapper__heading">
              <p className="addressdetails__wrapper__heading__title">
                Address Details *
                {props.search !== "" ? (
                  <span className="notedit">
                    <img src={error} alt="error" />
                    This Information cannot be changed
                  </span>
                ) : (
                  ""
                )}
              </p>
              <span>
                <img
                  alt="tip"
                  src={Tip}
                  style={{
                    marginBottom: "-3px",
                    // marginTop: '10px',
                    marginLeft: "10px",
                    width: "15px",
                    display: "none",
                  }}
                />
              </span>
            </div>
            <div className="addressdetails__wrapper__container">
              <img className="add-icon" src={add} alt="add" />
              <input
                type="text"
                className={props.search !== "" ? "editAdd" : "show__address"}
                value={
                  props.data.step1.addressDetails
                    ? props.data.step1.addressDetails.addressLine1 +
                      " " +
                      props.data.step1.addressDetails.addressLine2 +
                      " " +
                      props.data.step1.addressDetails.city +
                      " " +
                      props.data.step1.addressDetails.pincode +
                      " " +
                      props.data.step1.addressDetails.state
                    : ""
                }
              ></input>
              {props.data.step1.addressDetails.addressLine1 && (
                <p
                  className={props.search !== "" ? "editcross" : "input__cross"}
                  onClick={() => {
                    updateRootState(
                      "addressDetails",
                      {
                        addressLine1: "",
                        addressLine2: "",
                        city: "",
                        state: "",
                        pincode: "",
                      },
                      props
                    );
                  }}
                >
                  <img alt="closemap" src={closemap}></img>
                </p>
              )}
              <div
                className={props.search !== "" ? "editcursor" : "edit"}
                style={{
                  margin: "20px 20px 50px 20px",
                  overflow: "hidden",
                  borderRadius: "10px",
                  border: "1px solid",
                }}
              >
                {props.data.step1.location.lat &&
                  props.data.step1.location.lng && (
                    <Map
                      google={props.google}
                      center={props.data.step1.location}
                      height="400px"
                      zoom={15}
                      location={props.data.step1.location}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </Card>
      <div className="tariffdetails">
        <div className="tariffdetails__wrapper">
          <div className="tariffdetails__wrapper__container">
            <div className="tariffdetails__wrapper__container__leftside">
              <div className="tariffdetails__wrapper__heading">
                <p className="tariffdetails__wrapper__heading__title">
                  Slot Count and Tariff Details
                </p>
              </div>
              <div className="tariffdetails__wrapper__container__leftside_content">
                <AppBar position="static" color="default">
                  <Tabs
                    value={value}
                    className="vehicle_tab"
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                    <Tab
                      icon={
                        <img
                          alt="twowheeler"
                          src={
                            [activeTab] == "twoWheeler" ? MotorA : TwoWheeler
                          }
                        />
                      }
                      label="2 Wheeler"
                      {...a11yProps(0)}
                      onClick={() => updateActiveTab("twoWheeler")}
                    />
                    <Tab
                      icon={
                        <img
                          alt="fourwheeler"
                          src={
                            [activeTab] == "fourWheeler" ? CarA : FourWheeler
                          }
                        />
                      }
                      label="4 Wheeler"
                      {...a11yProps(1)}
                      onClick={() => updateActiveTab("fourWheeler")}
                    />

                    {/* <Tab
                      icon={
                        <img
                          alt="fleet"
                          src={
                            [activeTab] == 'fleet' ? FleetA : Fleet
                          }
                        />
                      }
                      label="Fleet"
                      {...a11yProps(2)}
                      onClick={() => updateActiveTab('fleet')}
                    /> */}
                  </Tabs>
                </AppBar>

                <div className="tariffdetails__wrapper__container__leftside_twowheeler">
                  <SwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                  >
                    {tabs.map((tab, index) => {
                      return (
                        <TabPanel
                          key={tab}
                          value={value}
                          index={index}
                          dir={theme.direction}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={5} style={{ padding: 0 }}>
                              <InputLabel
                                htmlFor="filled-adornment-amount"
                                style={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                }}
                              >
                                No of Slots
                                {/* <img
                                  alt="tip"
                                  src={Tip}
                                  style={{
                                    marginBottom: '-3px',
                                    marginTop: '10px',
                                    marginLeft: '10px',
                                    width: '15px',
                                    display: 'inline',
                                  }}
                                /> */}
                                <Button
                                  aria-describedby={id}
                                  variant="outlined"
                                  color="primary"
                                  onClick={(event) => handleClickSlot(event)}
                                  style={{
                                    float: "right",
                                    border: "none",
                                    paddingRight: "0px",
                                  }}
                                >
                                  <img
                                    src={Tip}
                                    style={{
                                      marginBottom: "-3px",
                                      marginTop: "-12%",
                                      marginLeft: "10px",
                                      width: "15px",
                                      display: "inline",
                                    }}
                                    alt=""
                                  />
                                </Button>
                                <Popover
                                  id={idSlot}
                                  open={openSlot}
                                  anchorEl={slotPolicy}
                                  onClose={handleCloseSlot}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      padding: "1rem",
                                      fontSize: "12px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Slot Count Info
                                      <br />
                                    </span>
                                    Select at least minimum one 1 slot for any
                                    vehicle type and Maximum 99999 <br />
                                    and should not be 0 (zero) for all vehicles
                                    2W,4W & Fleet
                                  </p>
                                </Popover>
                              </InputLabel>
                              <button
                                className="decrese btn"
                                disabled={
                                  storeEditData.step2[activeTab].totalSlots <=
                                    0 || slotFlag
                                }
                                style={{ top: "-5px" }}
                                onClick={() => {
                                  let data = {
                                    ...storeEditData.step2[activeTab],
                                  };
                                  data.totalSlots = data.totalSlots - 1;
                                  props.updateChildrenState(
                                    "step2",
                                    activeTab,
                                    data
                                  );
                                  console.log(
                                    "slot flag updation ",
                                    storeEditData.step2.slotFlag
                                  );
                                }}
                              >
                                <span>-</span>
                              </button>

                              <input
                                type="number"
                                id="number"
                                disabled={slotFlag}
                                min="0"
                                className="tariffdetails__wrapper__container__leftside_twowheeler_numoflots"
                                placeholder="100"
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                value={
                                  storeEditData.step2[activeTab].totalSlots
                                }
                                onWheel={(event) => event.currentTarget.blur()}
                                onChange={slotInputHandle}
                              />
                              <button
                                className="increse btn"
                                disabled={
                                  // storeEditData.step2[activeTab]
                                  //   .totalSlots <= 0
                                  slotFlag
                                }
                                style={{ top: "-5px" }}
                                onClick={() => {
                                  let data = {
                                    ...storeEditData.step2[activeTab],
                                  };
                                  data.totalSlots = data.totalSlots + 1;
                                  props.updateChildrenState(
                                    "step2",
                                    activeTab,
                                    data
                                  );
                                }}
                              >
                                <span>+</span>
                              </button>

                              {props.search !== "" ? (
                                <p className="notedit">
                                  <div className="error-block">
                                    <img src={error} alt="error" />
                                    <span>
                                      This Information change will be subjected
                                      to approval
                                    </span>
                                  </div>
                                </p>
                              ) : (
                                ""
                              )}
                            </Grid>
                            <Grid item xs={5} style={{ padding: 0 }}>
                              <InputLabel
                                htmlFor="filled-adornment-amount"
                                style={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                }}
                              >
                                Tariff
                                {/* <img
                                  alt="tip"
                                  src={Tip}
                                  style={{
                                    marginBottom: '-3px',
                                    marginTop: '10px',
                                    marginLeft: '10px',
                                    width: '15px',
                                    display: 'inline',
                                  }}
                                /> */}
                                <Button
                                  aria-describedby={id}
                                  variant="outlined"
                                  color="primary"
                                  onClick={(event) => handleClick(event)}
                                  style={{
                                    float: "right",
                                    border: "none",
                                    paddingRight: "0px",
                                  }}
                                >
                                  <img
                                    src={Tip}
                                    style={{
                                      marginBottom: "-3px",
                                      marginTop: "-12%",
                                      marginLeft: "10px",
                                      width: "15px",
                                      display: "inline",
                                    }}
                                    alt=""
                                  />
                                </Button>
                                <Popover
                                  id={id}
                                  open={open}
                                  anchorEl={anchorEl}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      padding: "1rem",
                                      fontSize: "12px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Tariff Info
                                      <br />
                                    </span>
                                    Select Base price value as minimum 1 and
                                    maximum 9999 rupees. <br />
                                    Additional & Long term prices are optional
                                  </p>
                                </Popover>
                              </InputLabel>

                              <span>Free</span>
                              <Switch
                                value="checkedC"
                                checked={storeEditData.step2[activeTab].paid}
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                                onClick={() => {
                                  let obj = {
                                    ...storeEditData.step2[activeTab],
                                  };
                                  obj.paid = !obj.paid;
                                  props.updateChildrenState(
                                    "step2",
                                    activeTab,
                                    obj
                                  );
                                }}
                                id="myCheckOne"
                              />
                              <span>Paid</span>

                              <div
                                id="bike-tariff"
                                className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif"
                                style={
                                  storeEditData.step2[activeTab].paid
                                    ? activeFieldStyle
                                    : styles
                                }
                              >
                                <InputLabel htmlFor="filled-adornment-amount">
                                  Base Price
                                </InputLabel>
                                <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_baseprice">
                                  <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_baseprice_left">
                                    <select
                                      type="text"
                                      id="baseHour"
                                      value={
                                        storeEditData.step2[activeTab].baseHour
                                      }
                                      onChange={(evt) => {
                                        let obj = {
                                          ...storeEditData.step2[activeTab],
                                        };
                                        obj.baseHour = evt.target.value;

                                        props.updateChildrenState(
                                          "step2",
                                          activeTab,
                                          obj
                                        );
                                      }}
                                    >
                                      <option value="1">0-1 Hour</option>
                                      <option value="2">0-2 Hour</option>
                                      <option value="3">0-3 Hour</option>
                                    </select>
                                  </div>
                                  <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_baseprice_right">
                                    {/* <span style=';'>&#8377;</span> */}
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        paddingRight: "5px",
                                      }}
                                    >
                                      &#8377;
                                    </span>
                                    <input
                                      type="number"
                                      onWheel={(event) =>
                                        event.currentTarget.blur()
                                      }
                                      value={
                                        storeEditData.step2[activeTab].basePrice
                                      }
                                      min={0}
                                      step="10.00"
                                      max="2500"
                                      placeholder="10.00"
                                      onChange={(evt) => {
                                        let obj = {
                                          ...storeEditData.step2[activeTab],
                                        };

                                        obj.basePrice = parseFloat(
                                          1 * evt.target.value
                                        );

                                        props.updateChildrenState(
                                          "step2",
                                          activeTab,
                                          obj
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                                <div>
                                  {storeEditData.step2[activeTab].paid ===
                                    true &&
                                  (storeEditData.step2[activeTab].basePrice <=
                                    0 ||
                                    storeEditData.step2[activeTab].basePrice ===
                                      "") ? (
                                    <p className="errorClass">
                                      Base price should be more then 0
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <InputLabel htmlFor="filled-adornment-amount">
                                  Additional Hour
                                </InputLabel>
                                <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_additionalHour">
                                  <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_additionalHour_left">
                                    <select
                                      type="text"
                                      id="additionalHour"
                                      value={
                                        storeEditData.step2[activeTab]
                                          .additionalHour
                                      }
                                      onChange={(evt) => {
                                        let obj = {
                                          ...storeEditData.step2[activeTab],
                                        };
                                        obj.additionalHour = evt.target.value;

                                        props.updateChildrenState(
                                          "step2",
                                          activeTab,
                                          obj
                                        );
                                      }}
                                    >
                                      <option value="1">+1 Hour</option>
                                      <option value="2">+2 Hour</option>
                                      <option value="3">+3 Hour</option>
                                    </select>
                                  </div>
                                  <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_additionalHour_right">
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        paddingRight: "5px",
                                      }}
                                    >
                                      &#8377;
                                    </span>
                                    <input
                                      type="number"
                                      placeholder="10.00"
                                      min={0}
                                      onWheel={(event) =>
                                        event.currentTarget.blur()
                                      }
                                      step="10.00"
                                      max="2500"
                                      value={
                                        storeEditData.step2[activeTab]
                                          .additionalPrice
                                      }
                                      onChange={(evt) => {
                                        let obj = {
                                          ...storeEditData.step2[activeTab],
                                        };
                                        obj.additionalPrice = parseFloat(
                                          1 * evt.target.value
                                        );

                                        props.updateChildrenState(
                                          "step2",
                                          activeTab,
                                          obj
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                                <div>
                                  {storeEditData.step2[activeTab].paid ===
                                    true &&
                                  (storeEditData.step2[activeTab]
                                    .additionalPrice <= 0 ||
                                    storeEditData.step2[activeTab]
                                      .additionalPrice === "") ? (
                                    <p className="errorClass">
                                      Additional price should be more then 0
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <InputLabel htmlFor="filled-adornment-amount">
                                  Long term
                                </InputLabel>
                                <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_longterm">
                                  <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_longterm_left">
                                    <select
                                      type="text"
                                      id="longtermHour"
                                      value={
                                        storeEditData.step2[activeTab]
                                          .selectedLongterm
                                          ? storeEditData.step2[activeTab]
                                              .selectedLongterm
                                          : storeEditData.step2[activeTab]
                                              .pricePerDay !== 0
                                          ? "pricePerDay"
                                          : storeEditData.step2[activeTab]
                                              .pricePerWeek !== 0
                                          ? "pricePerWeek"
                                          : storeEditData.step2[activeTab]
                                              .pricePerMonth !== 0
                                          ? "pricePerMonth"
                                          : storeEditData.step2[activeTab]
                                              .selectedLongterm
                                      }
                                      onChange={(evt) => {
                                        let obj = {
                                          ...storeEditData.step2[activeTab],
                                        };
                                        obj.selectedLongterm = evt.target.value;

                                        props.updateChildrenState(
                                          "step2",
                                          activeTab,
                                          obj
                                        );
                                      }}
                                    >
                                      <option value="pricePerDay">
                                        Price/Day
                                      </option>
                                      <option value="pricePerWeek">
                                        Price/Week
                                      </option>
                                      <option value="pricePerMonth">
                                        Price/Month
                                      </option>
                                    </select>
                                  </div>
                                  <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_longterm_right">
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        paddingRight: "5px",
                                      }}
                                    >
                                      &#8377;
                                    </span>

                                    <input
                                      type="number"
                                      min={0}
                                      onWheel={(event) =>
                                        event.currentTarget.blur()
                                      }
                                      placeholder="10.00"
                                      value={
                                        storeEditData.step2[activeTab]
                                          .pricePerDay !== 0
                                          ? storeEditData.step2[activeTab]
                                              .pricePerDay
                                          : storeEditData.step2[activeTab]
                                              .pricePerWeek !== 0
                                          ? storeEditData.step2[activeTab]
                                              .pricePerWeek
                                          : storeEditData.step2[activeTab]
                                              .pricePerMonth !== 0
                                          ? storeEditData.step2[activeTab]
                                              .pricePerMonth
                                          : storeEditData.step2[activeTab]
                                              .pricePerDay
                                      }
                                      onChange={(evt) => {
                                        var keyData =
                                          storeEditData.step2[activeTab]
                                            .pricePerDay !== 0
                                            ? "pricePerDay"
                                            : storeEditData.step2[activeTab]
                                                .pricePerWeek !== 0
                                            ? "pricePerWeek"
                                            : storeEditData.step2[activeTab]
                                                .pricePerMonth !== 0
                                            ? "pricePerMonth"
                                            : "pricePerDay";

                                        changeTerms(
                                          activeTab,
                                          keyData,
                                          parseInt(evt.target.value),
                                          props
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </TabPanel>
                      );
                    })}
                  </SwipeableViews>
                </div>
              </div>
            </div>
            <div className="tariffdetails__wrapper__container__rightside">
              <div className="tariffdetails__wrapper__heading">
                <p className="tariffdetails__wrapper__heading__title">
                  Operational Timings
                </p>
              </div>
              <div className="tariffdetails__wrapper__container__rightside__heading">
                <p>
                  <b>Standard Operating Hours </b>
                  <img
                    alt="tip"
                    src={Tip}
                    style={{
                      marginBottom: "-3px",
                      marginTop: "10px",
                      marginLeft: "10px",
                      width: "15px",
                      display: "none",
                    }}
                  />
                </p>
                <div>
                  {storeEditData.step2.timings.FRIDAY.isChecked ||
                  storeEditData.step2.timings.SATURDAY.isChecked ||
                  storeEditData.step2.timings.SUNDAY.isChecked ||
                  storeEditData.step2.timings.MONDAY.isChecked ||
                  storeEditData.step2.timings.TUESDAY.isChecked ||
                  storeEditData.step2.timings.WEDNESDAY.isChecked ||
                  storeEditData.step2.timings.THURSDAY.isChecked ? (
                    ""
                  ) : (
                    <p className="errorClass">please select atleast one</p>
                  )}
                </div>
              </div>
              <OprationalTimmings
                data={props.data.step2}
                updateRootState={props.updateRootState}
                updateChildrenState={props.updateChildrenState}
                updateParentState={updateParentState}
              />
              <div className="customRule">
                <p className="tariffdetails__wrapper__heading__title">
                  Custom Availability
                </p>
                <img
                  alt="tip"
                  src={Tip}
                  style={{
                    marginBottom: "5px",
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "15px",
                    display: "none",
                  }}
                />

                <Button
                  variant="contained"
                  color="#E46A76"
                  onClick={showCustomRule}
                >
                  Add Custom Rule
                </Button>
              </div>
              {(showTheDiv > 0 || props.data.step2.customData) && (
                <CustomAvailability
                  data={props.data.step2}
                  count={showTheDiv}
                  updateRootState={props.updateRootState}
                  updateChildrenState={props.updateChildrenState}
                  updateParentState={updateParentState}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="AddParkingStepThree">
        <Card>
          <div className="AddParkingStepThree__wrapper">
            <div className="AddParkingStepThree__wrapper__heading">
              <p className="AddParkingStepThree__wrapper__heading__title">
                Parking Facilities & Services Near by
                {props.search !== "" ? (
                  <span className="notedit">
                    <img src={error} alt="error" />
                    This Information change will be subjected to approval
                  </span>
                ) : (
                  ""
                )}
              </p>
            </div>
            <div className="AddParkingStepThree__wrapper__container">
              <div className="AddParkingStepThree__wrapper__container__leftside">
                <ParkingFacilities
                  data={props.data}
                  updateRootState={props.updateRootState}
                  updateChildrenState={props.updateChildrenState}
                />
              </div>
              <div className="AddParkingStepThree__wrapper__container__rightside">
                <ServicesNearBy
                  data={props.data}
                  updateRootState={props.updateRootState}
                  updateChildrenState={props.updateChildrenState}
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default EditParkingStepOne;
