// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useEffect, useState } from 'react';
import ImageUploader from 'react-images-upload';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Card } from '@material-ui/core';
import Map from '../../../../../Components/MapAddress';
import uploadImageService from '../../../../../Utils/uploadImageService';
import Loader from "../../../../../Components/Preloader";
import IconA from '../../../../../Assets/Images/IconA.png';
import IconB from '../../../../../Assets/Images/IconB.png';
import IconAA from '../../../../../Assets/Images/IconAA.png';
import IconBB from '../../../../../Assets/Images/IconBB.png';
import IconR from '../../../../../Assets/Images/IconR.png';
import IconRR from '../../../../../Assets/Images/IconRR.png';
import UploadIcon from '../../../../../Assets/Images/cloud-computing.svg';
import error from '../../../../../Assets/Images/error.png';
import add from '../../../../../Assets/Images/add.png';
import closemap from '../../../../../Assets/Images/deletemap.png';
import './style.scss';
import './../MainForm.scss';
import { getCookie } from '../../../../../Utils/cookies';
import { connect } from 'react-redux';
import { updateToasterConfig } from './../../../../../../src/redux/modules/app/actions';
import { updateBreadcrumbs } from '../../../../../redux/modules/app/actions';
import { withRouter } from 'react-router';
//Global variable declaration
var addImage = [];
var deleteImageList = [];
const updateParentState = (parentKey, childKey, value, props) => {
  let obj = { ...props.data[parentKey] };
  obj[childKey] = value;
  props.updateChildrenState('step1', parentKey, obj);
};
//Global function declarations
const updateRootState = (key, value, props) => {
  props.updateChildrenState('step1', key, value);
};
//Global function declarations for drag over
const handleDragOver = evt => {
  evt.preventDefault();
  return false;
};
//Global function declarations for handle drop
const handleDrop = (evt, props) => {
  evt.preventDefault();
  evt.stopPropagation();

  if (evt.dataTransfer.files[0].size > 2084000) {
    document.getElementById('myDialog').showModal();
    setTimeout(() => {
      document.getElementById('myDialog').close();
    }, 3000);
  } else {
    let imagesData = evt.dataTransfer.files;

    //console.log(imagesData)
    uploadImageService.uploadImage(
      imagesData,
      uploadImageServiceCallBack,
      props,
    );
  }
};
//Global function declarations for image upload
const uploadImageServiceCallBack = (imageData, props) => {
  if (props.data.images.length >= 5) {
    return false;
  }

  addImage = [imageData, ...props.data.images];
  addImage.push(imageData);
  const unique = addImage
    .map(e => e.name)
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter(e => addImage[e])
    .map(e => addImage[e]);
  props.updateChildrenState('step1', 'images', unique);
};

//@component
//AddParkingStepOne
function AddParkingStepOne(props) {
  props.updateRootState(0, 1);
  const [clearCache, setCache] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showToast,setshowToast]=useState(false)

  const removeToast=()=>{
    setTimeout(()=>{
      setshowToast(false)
    },3000)
  }
  //function for component mount
  useEffect(() => {
    for (var i = addImage.length; i > 0; i--) {
      addImage.pop();
    }
    setCache(true);
    updateParentState(
      'parkingBusinessType',
      'uid',
      3,
      props,
    )
  }, [clearCache]);
  //function for onDrop
  const onDrop = (evt, props) => {

    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if(!acceptedImageTypes.includes(evt[0]?.type))
    {
      setshowToast(true)
      console.log('error uploading file')
      removeToast()
      return
    }
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    
    //console.log(evt[0].type)
    let imagesData = evt.slice(-5);
    if (imagesData.length === 0 || imagesData[0].size > 2084000) {
      document.getElementById('myDialog').showModal();
      setTimeout(() => {
        document.getElementById('myDialog').close();
      }, 2000);
    } else {
      uploadImageService.uploadImage(
        imagesData,
        uploadImageServiceCallBack,
        props,
      );
    }
  };
  //function for image remove
  const removeImage = (imageData, props) => {
    if (imageData[0].file === undefined) {
      deleteImageList.push(imageData[0]);
    }

    props.updateChildrenState(
      'step1',
      'deleteImagesFromSpace',
      deleteImageList,
    );
  };
  //conditional reference
  if (props.data.images.length > 0) {
    addImage = [...props.data.images];
  }
  // To validate for parking space name field
  const parkingNameValidator = event => {
    var inputBox = document.getElementById(event.target.id);

    var invalidChars = [
      "-", "_", "+", "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")",
      "<", ">", "?", "/", "{", "}", "[", "]", "|", ",", ".", ":", ";", "'", "", ",", "\\", "\""
    ];
    inputBox.addEventListener("keydown", function (e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
    inputBox.addEventListener('paste', e => e.preventDefault());
    if (event.target.value.length <= 100) {
      if (invalidChars.includes(event.target.value)) {
        event.preventDefault();
        props.updateChildrenState(
          'step1',
          'parkingName',
          '',
        );
      } else {
        props.updateChildrenState(
          'step1',
          'parkingName',
          event.target.value,
        );
      }
    }
  }
  // funtion for validation for contact number field
  const contactNumberValidator = evt => {
    let contactNumberId = evt.target.id;
    var inputBox = document.getElementById(contactNumberId);
    var invalidChars = [
      "-",
      "+",
      "e",
      "."
    ];
    inputBox.addEventListener("keydown", function (e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });

    if ((evt.target.value === '' || /^[0-9\b]+$/.test(evt.target.value)) && evt.target.value.length <= 14) {
      props.updateChildrenState(
        'step1',
        'contactNumber',
        evt.target.value,
      );
    }
  }
  // To validate contact name
  const contactNameValidator = event => {
    var inputBox = document.getElementById(event.target.id);

    var invalidChars = [
      "-", "_", "+", "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")",
      "<", ">", "?", "/", "{", "}", "[", "]", "|", ",", ".", ":", ";", "'", "", ",", "\\", "\"",
      "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "="
    ];
    inputBox.addEventListener("keydown", function (e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
    inputBox.addEventListener('paste', e => e.preventDefault());
    if (event.target.value.length <= 100) {
      if (invalidChars.includes(event.target.value)) {
        event.preventDefault();
        props.updateChildrenState(
          'step1',
          'contactName',
          '',
        );
      } else {
        props.updateChildrenState(
          'step1',
          'contactName',
          event.target.value,
        )
      }
    }
  }
  // To validate description
  const descriptionValidator = event => {
    var inputBox = document.getElementById(event.target.id);
    inputBox.addEventListener('paste', e => e.preventDefault());

    props.updateChildrenState(
      'step1',
      'description',
      event.target.value,
    )
  }

  return (
    <div className="contactdetails">
      <Card>
        <div className="contactdetails__wrapper">
          <div className="contactdetails__wrapper__heading">
            <p className="contactdetails__wrapper__heading__title">
              Parking Space and Contact Details
              {props.search !== '' ? (
                <span className="notedit">
                  <img src={error} alt="error" />
                  This Information change will be subjected to
                  approval
                </span>
              ) : (
                ''
              )}
            </p>
            <span>
              {/* <img
                src={Tip}
                style={{
                  marginBottom: '-3px',
                  marginTop: '10px',
                  marginLeft: '10px',
                  width: '15px',
                  display: 'inline',
                }}
                alt=""
              /> */}
            </span>
          </div>
          <div className="contactdetails__wrapper__container">
            <div className="contactdetails__wrapper__container__leftside">
              <label>Parking Space Name*</label>
              <TextField
                id='parkingspacename'
                fullWidth
                type="text"
                name="parkingName"
                inputProps={{ maxLength: 100, minLength: 2 }}
                value={props.data.parkingName}
                onChange={event => parkingNameValidator(event)}
              />
              {props.data.parkingName.length < 2 &&
                props.data.parkingName.length > 0 ? (
                <p className="errorClass">
                  Minimum 2 characters required
                </p>
              ) : (
                <p></p>
              )}
              <br />
              <label>Description*</label>
              <TextField
                id='description'
                className="descpBoxtext"
                inputProps={{ maxLength: 500, minLength: 10 }}
                fullWidth
                multiline
                placeholder="Enter Description of your parking space [500 characters max]"
                rows="5"
                type="textarea"
                name="description"
                value={props.data.description}
                onChange={event => descriptionValidator(event)}
              />
              {props.data.description.length < 10 &&
                props.data.description.length > 0 ? (
                <p className="errorClass">
                  Minimum 10 characters required
                </p>
              ) : (
                <p></p>
              )}
              <label>Contact Name*</label>
              <TextField
                id='contactname'
                fullWidth
                type="text"
                name="contactName"
                value={props.data.contactName}
                inputProps={{ maxLength: 100, minLength: 2 }}
                onChange={(event) => contactNameValidator(event)}
              />
              {props.data.contactName.length < 2 &&
                props.data.contactName.length > 0 ? (
                <p className="errorClass">
                  Minimum 2 characters required
                </p>
              ) : (
                <p></p>
              )}
              <label>Contact Number*</label>
              <TextField
                fullWidth
                id='contactnumber'
                type="text"
                className="phone_field"
                name="contactNumber"
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       <p className="num_prefix">+91</p>
                //     </InputAdornment>
                //   ),
                // }}
                value={props.data.contactNumber}
                onChange={evt => contactNumberValidator(evt)}
              />
              {props.data.contactNumber.length < 10 &&
                props.data.contactNumber.length > 0 ? (
                <p className="errorClass" >
                  Minimum 10 numbers required
                </p>
              ) : (
                <p></p>
              )}
              <label>Contact Email*</label>
              <TextField
                fullWidth
                type="email"
                autoComplete="off"
                name="contactEmail"
                value={props.data.contactEmail}
                inputProps={{ maxLength: 100, minLength: 5 }}
                onChange={evt => {
                  props.updateChildrenState(
                    'step1',
                    'contactEmail',
                    evt.target.value,
                  )
                }}
              />
              {props.data.contactEmail.length < 5 &&
                props.data.contactEmail.length > 0 ? (
                <p className="errorClass">
                  Minimum 5 characters required
                </p>
              ) :
                (
                  <p></p>
                )}
              <label>Parking Type*</label>
              <br />
              {((getCookie('role') === 'MULTITENANT_ADMIN') || (getCookie('role') === 'CLOSED_GROUP_PARKING_PROVIDER') || (getCookie('role') === 'FACILITY_ADMIN')) && (
                <>
                <div
                 className={
                   props.data.parkingBusinessType.uid == 1
                     ? 'button__wrapper active'
                     : 'button__wrapper'
                 }
                 onClick={() =>
                   updateParentState(
                     'parkingBusinessType',
                     'uid',
                     1,
                     props,
                   )
                 }
               >
                 <div className="button__wrapper_left">
                   <img
                     src={
                       props.data.parkingBusinessType.uid == 1
                         ? IconA
                         : IconB
                     }
                     alt="Icon"
                   ></img>
                 </div>
                 <div className="button__wrapper_right">
                   <p className="desc">Corporate Parking</p>
                   <p className="desc1">Companies, Tech Park, etc.</p>
                 </div>
               </div>
               <div
                 className={
                   props.data.parkingBusinessType.uid == 2
                     ? 'button__wrapper active'
                     : 'button__wrapper'
                 }
                 onClick={() =>
                   updateParentState(
                     'parkingBusinessType',
                     'uid',
                     2,
                     props,
                   )
                 }
               >
                 <div className="button__wrapper_left">
                   <img
                     src={
                       props.data.parkingBusinessType.uid == 2
                         ? IconAA
                         : IconBB
                     }
                     alt="Icon"
                   ></img>
                 </div>
                 <div className="button__wrapper_right">
                   <p className="desc">Commercial Parking</p>
                   <p className="desc1">
                     Malls, Hospitals, Private parking, etc.
                   </p>
                 </div>
               </div>
               </>
              )}
              {(getCookie('role')==='RESIDENTIAL_GROUP_PARKING_PROVIDER'|| getCookie('role')==="RESIDENTIAL_FACILITY_ADMIN") && (
                <>
                  <div
                 className={
                   props.data.parkingBusinessType.uid == 3
                     ? 'button__wrapper active'
                     : 'button__wrapper'
                 }
                 onClick={() =>
                   updateParentState(
                     'parkingBusinessType',
                     'uid',
                     3,
                     props,
                   )
                 }
               >
                 <div className="button__wrapper_left">
                   <img
                     src={
                       props.data.parkingBusinessType.uid == 3
                         ? IconR
                         : IconRR
                     }
                     alt="Icon"
                   ></img>
                 </div>
                 <div className="button__wrapper_right">
                   <p className="desc">Residential Parking</p>
                   <p className="desc1" style={{fontSize:'10px'}}>
                     Apartments.
                   </p>
                 </div>
               </div>
                </>
              )}
             
            </div>
            <div className="contactdetails__wrapper__container__rightside">
              <label>Add Parking Space Photos</label>
              <dialog id="myDialog">Image size is too big !!!</dialog>
              <div className="contactdetails__wrapper__container__rightside__box">
                <div
                  className="drag__and_drop"
                  onDragOver={evt => handleDragOver(evt)}
                  onDrop={evt => handleDrop(evt, props)}
                >
                  {isLoading && <Loader />}
                  <p className="contactdetails__wrapper__container__rightside__box_upimg">
                    <ImageUploader
                      withIcon={true}
                      buttonText="Choose images"
                      onChange={evt => onDrop(evt, props)}
                      imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                      singleImage={true}
                    ></ImageUploader>
                    <img
                      src={UploadIcon}
                      alt="UploadIcon"
                      className="UploadIcon"
                    />
                  </p>
                  <p className="contactdetails__wrapper__container__rightside__box_text">
                    Drag and Drop a file here or click
                  </p>
                  <p className="contactdetails__wrapper__container__rightside__box_text1">
                    You can upload upto 5 photos each should be less
                    than 2mb
                  </p>
                </div>

                <div className="contactdetails__wrapper__container__rightside__box_imagesbox">
                  {addImage.map((item, index) => {
                    return (
                      <div className="images" key={index}>
                        <img
                          src={
                            item.newImg
                              ? item.src
                              : `data:image/png;base64,${item}`
                          }
                          alt="imgSrc"
                        />

                        <span
                          className="delete-img"
                          onClick={() => {
                            var dataImage = addImage.splice(index, 1);
                            props.updateChildrenState(
                              'step1',
                              'images',
                              addImage,
                            );
                            removeImage(dataImage, props);
                          }}
                        >
                          X
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Card>
        {/* <AddressDetails /> */}
        <div className="addressdetails">
          <div className="addressdetails__wrapper">
            <div className="addressdetails__wrapper__heading">
              <p className="addressdetails__wrapper__heading__title">
                Address Details *
                {props.search !== '' ? (
                  <span className="notedit">
                    <img src={error} alt="error" />
                    This Information cannot be changed
                  </span>
                ) : (
                  ''
                )}
              </p>
              <span>
                {/* <img
                  src={Tip}
                  style={{
                    marginBottom: '-3px',
                    marginLeft: '10px',
                    width: '15px',
                    display: 'inline',
                  }}
                  alt=""
                /> */}
              </span>
            </div>
            <div className="addressdetails__wrapper__container">
              <img className="add-icon" src={add} alt="add" />
              <input
                type="text"
                className={
                  props.search !== '' ? 'editAdd' : 'show__address'
                }
                disabled
                value={
                  props.data.addressDetails
                    ? props.data.addressDetails.addressLine1 +
                    ' ' +
                    props.data.addressDetails.addressLine2 +
                    ' ' +
                    props.data.addressDetails.city +
                    ' ' +
                    props.data.addressDetails.state +
                    ' ' +
                    props.data.addressDetails.pincode
                    : ''
                }
              ></input>

              <dialog id="myDialogBox">
                Pincode should not be empty!!!
              </dialog>

              {props.data.addressDetails.addressLine1 && (
                <p
                  className={
                    props.search !== '' ? 'editcross' : 'input__cross'
                  }
                  onClick={() => {
                    updateRootState(
                      'addressDetails',
                      {
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        state: '',
                        pincode: '',
                      },
                      props,
                    );
                  }}
                >
                  <img alt="closemap" src={closemap}></img>
                </p>
              )}
              <div
                className={
                  props.search !== '' ? 'editcursor' : 'edit'
                }
                style={{
                  margin: '20px 20px 50px 20px',
                  overflow: 'hidden',
                  borderRadius: '10px',
                  border: '1px solid',
                }}
              >
                <Map
                  google={props.google}
                  center={
                    props.data.location.lat === '' &&
                      props.data.location.lng === ''
                      ? { lat: 12.9716, lng: 77.5946 }
                      : props.data.location
                  }
                  height="400px"
                  zoom={15}
                  location={props.data.location}
                  updateRootState={(key, value) => {

                    if (value.pincode === '') {
                      document
                        .getElementById('myDialogBox')
                        .showModal();
                      setTimeout(() => {
                        document
                          .getElementById('myDialogBox')
                          .close();
                      }, 3000);
                    }
                    updateRootState(key, value, props);
                  }}
                />
                {/* } */}
              </div>
            </div>
          </div>
        </div>
      </Card>

      {showToast&&(<div style={{position:'absolute',top:'2px',right:'4px',width:'300px',height:'50px',backgroundColor:'red',color:'white',fontSize:'14px',alignItems:'center',justifyContent:'space-between',display:'flex',borderRadius:'5px',padding:'5px'}}>
        <p>Invalid file type</p>
        <p style={{cursor:'pointer'}} onClick={()=>{setshowToast(false)}}>&#10006;</p>
      </div>)}
    </div>
  )
}

export default AddParkingStepOne;
// export default withRouter(
//   connect(
//     state => ({
//       auth: state.app.auth,
//     }),
//     { updateToasterConfig, updateBreadcrumbs },
//   )(AddParkingStepOne),
// );