// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import MomentUtils from "@date-io/moment";
import Pagination from "@material-ui/lab/Pagination";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import WarningSharpIcon from "@material-ui/icons/WarningSharp";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getCookie } from "../../../Utils/cookies";
import {
  updateBreadcrumbs,
  updateToasterConfig,
} from "../../../redux/modules/app/actions";
import pdfImg from "../../../Assets/icons/pdf1.png";
import excelImg from "../../../Assets/icons/excel1.png";
import httpRequest from "../../../Utils/httpRequest";
import Loader from "../../../Components/Preloader";
import UrlConfig from "../../../Constants/apiUrls";
import { registerHeaderCell } from "../../../Data/report-page";
import EndUserRegisterUseStyles from "./styles";

//@component
//Register end user report
function RegisterEndUser(props) {
  const classes = EndUserRegisterUseStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const [parkingRegisterList, setParkingRegisterList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [fromTime, setFromTime] = useState(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0); 
    return date.getTime(); 
  });
  const [fromFill, setFromFill] = useState("");

  const [toTime, setToTime] = useState(() => {
    const date = new Date();
    //date.setHours(23, 59, 59, 999); 
    return date.getTime(); 
  });
  const [tableDataNotFound] = useState("");
  const [responseDataNotFound, setResponseDataNotFound] = useState("");
  const [open, setOpen] = useState(false);
  const [toDateError, setToDateError] = useState("");
  const [formatType, setFormatType] = useState("");
  const [listSelectedFlag, setListSelectedFlag] = useState(false);

  //style method for dialog
  const styles = (theme) => ({
    root: {
      margin: 0,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  // style to support datepicker
  const useStyles = makeStyles(() =>
    createStyles({
      componentStyle: {
        verticalAlign: "middle",
        fontSize: "12px",
        fontWeight: "600",
        width: (params) => (params.width ? params.width : "auto"),

        "& fieldset": {
          border: "solid 2px #0070D8",
        },
        "& .MuiInputBase-root": {
          height: (params) => (params.height ? params.height : "auto"),
          color: (params) => (params.color ? params.color : "inherit"),
        },
      },
    })
  );
  // for datepicker styling
  const classesDatePicker = useStyles({
    color: "red",
    width: "100%",
    height: "100%",
  });
  //method for dialog
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  //api call to generate reports
  const generateReportHandler = (pageNumber) => {
    setPage(pageNumber);
    setIsLoading(true);
    setListSelectedFlag(true);
    if (fromTime > toTime) {
      setToDateError("To-Date cannot be set to Past Date");
    }
    httpRequest.get(
      "reportRegisterList",
      {
        "Content-Type": "application/json",
      },
      successCallbackReport,
      errorCallback,
      {
        fromTime: fromTime,
        toTime: toTime || new Date(),
        page: pageNumber,
        size: size,
      }
    );
  };
  // success callback for report
  const successCallbackReport = (jsonResponse) => {
    setIsLoading(false);
    setResponseDataNotFound("");
    if (jsonResponse.data?.page?.totalPages < 1) {
      setListSelectedFlag(false);
      setParkingRegisterList([]);
    }
    if (
      jsonResponse.data?.page?.totalPages > 0 &&
      jsonResponse.data?._embedded?.results
    ) {
      setParkingRegisterList(jsonResponse.data._embedded.results);
      setListSelectedFlag(true);
      setResponseDataNotFound("");
      setSize(jsonResponse.data.page.size);
      setPage(jsonResponse.data.page.number);
      setTotalPages(jsonResponse.data.page.totalPages);
    }
  };
  // error callback
  const errorCallback = (error) => {
    setIsLoading(false);
    if (error.message !== "Internal Server Error, Please try again later") {
      //any error message
    }
    if (error) {
      setResponseDataNotFound(
        "Data Not Found, Please Refine selection above !"
      );
    }
  };
  //error callback
  const errorCallbackCheck = (error) => {
    setIsLoading(false);
    if (error.message !== "Internal Server Error, Please try again later") {
      //any error message
    }
    if (error) {
      setResponseDataNotFound("Data not found, Unable to Download !");
    }
  };
  //api call for excel/pdf download
  const reportDownload = () => {
    setIsLoading(true);
    httpRequest.get(
      "reportRegisterListCheck",
      {
        "Content-Type": "application/json",
      },
      successCallbackReportCheck,
      errorCallbackCheck,
      {
        fromTime: new Date(fromTime).getTime(),
        toTime: new Date(toTime).getTime(),
      }
    );
  };
  //function to check if response is empty and then conditionally download
  const successCallbackReportCheck = (jsonResponse) => {
    setIsLoading(false);
    if (jsonResponse.data?.page?.totalPages < 1) {
      setResponseDataNotFound("");
      setListSelectedFlag(false);
      setParkingRegisterList([]);
      props.dispatch(
        updateToasterConfig({
          show: true,
          message:
            "Error! No data available for download for selected date range",
          color: "red",
        })
      );
    }
    if (jsonResponse?.data?._embedded?.results) {
      axios({
        url: UrlConfig("reportRegisterDownload", {
          fromTime: new Date(fromTime).getTime(),
          toTime: new Date(toTime).getTime(),
          formatType: formatType,
        }),
        method: "GET",
        responseType: "blob", // important
        headers: {
          realmname: getCookie('realmName'),
          Authorization: "bearer " + getCookie("auth"),
        },
      })
        .then((response) => {
          let fileName = response.headers["content-disposition"].split(
            "filename="
          )[1];
          let fileType = response.headers["content-type"];
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // IE variant
            window.navigator.msSaveOrOpenBlob(
              new Blob([response.data], {
                type: fileType,
              }),
              fileName
            );
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        })
        .catch(() => {
          // Display the Appropriate Error Message
        });
    }
  };
  //function to clear from and to fillers
  const clearHandler = () => {
    setFromFill("");
  };
  //validation and setting startdatetime
  const fromHandler = (event) => {
    if (new Date(event._d) <= new Date()) {
      const dateWithMidnightTime = new Date(event);
      dateWithMidnightTime.setHours(0, 0, 0, 0);
      console.log(dateWithMidnightTime.getTime())
      let datems = event._d.getTime();
      if (datems <= toTime) {
        setFromTime( dateWithMidnightTime.getTime());
        setFromFill("fill");
      }
      if (datems > toTime) {
        props.dispatch(
          updateToasterConfig({
            show: true,
            message: "Error! From date selected is greater than To date.",
            color: "red",
          })
        );
      }
    }
  };
  //validation and setting enddatetime
  const toHandler = useCallback((event) => {
   if(new Date(event._d).getTime()<new Date().getTime())
    {
     const dateWithMidnightTime = new Date(event);
     dateWithMidnightTime.setHours(23, 59, 59, 999);
     setToTime(dateWithMidnightTime.getTime());
     setToDateError("");
    }
    else
    {
      setToTime(new Date().getTime())
      setToDateError("")
    }
  }, []);
  //function to open excel popup
  const excelPopup = (value) => {
    setOpen(true);
    setFormatType(value);
  };
  //updating breadcrumb and list function call
  useEffect(() => {
    props.dispatch(updateBreadcrumbs("reportsRegisterUsers"));
    clearHandler();
  }, []);

  //Pagination change handle for next/previous/click on Page Number
  const handleChange = (event, value) => {
    setPageNumber(value);
    let currpage;
    currpage = value - 1;
    generateReportHandler(currpage);
  };
  return (
    <>
      {isLoading && <Loader />}
      <Grid container direction="column">
        <Grid
          item
          container
          justify="space-around"
          className={classes.outerRowGrid}
        >
          <Grid item md={3} className={classes.datePicker}>
            <label className={classes.dateLabel}>From: </label>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                InputProps={{
                  disableUnderline: true,
                  className: classesDatePicker.componentStyle,
                }}
                autoOk={true}
                disableFuture
                onKeyDown={(e) => e.preventDefault()}
                variant="inline"
                ampm={false}
                className={classes.fromDatePicker}
                label=""
                value={fromTime}
                onChange={fromHandler}
                format="DD/MM/yyyy"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={3} className={classes.datePicker}>
            <label className={classes.dateLabel}>To: </label>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                InputProps={{
                  disableUnderline: true,
                  className: classesDatePicker.componentStyle,
                }}
                autoOk={true}
                disableFuture
                onKeyDown={(e) => e.preventDefault()}
                minDate={fromTime}
                variant="inline"
                ampm={false}
                className={classes.toDatePicker}
                label=""
                value={toTime}
                onChange={toHandler}
                format="DD/MM/yyyy"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={3}>
            <button
              type="button"
              className={classes.generateReportButton}
              onClick={() => generateReportHandler(page)}
            >
              Generate Report
            </button>
          </Grid>
          {/* <Grid item style={{ marginTop: "0.5%" }} md={1}>
            <IconButton
              // disabled={
              //   toDateError.length !== 0 ||
              //   fromDateError.length !== 0 ||
              //   fromFill.length === 0
              // }
              style={{ marginLeft: "85%" }}
              className={classes.downloadButton}
              onClick={() => excelPopup("PDF")}
            >
              <img
                className={classes.reportExportImage}
                alt="pdf"
                src={pdfImg}
              />
            </IconButton>
          </Grid> */}
          <Grid item style={{ marginTop: "0.5%" }} md={1}>
            <IconButton
              // disabled={
              //   toDateError.length !== 0 ||
              //   fromDateError.length !== 0 ||
              //   fromFill.length === 0
              // }
              className={classes.downloadButton}
              onClick={() => excelPopup("EXCEL")}
            >
              <img
                className={classes.reportExportImage}
                alt="excel"
                src={excelImg}
              />
            </IconButton>
          </Grid>
        </Grid>
        <>
          <Grid
            container
            direction="row"
            justify="center"
            className={classes.tableGrid}
          >
            <TableContainer className={classes.paper} component={Paper}>
              <Table
                className={classes.table}
                aria-label="register enduser table"
              >
                <TableHead>
                  <TableRow>
                    {registerHeaderCell.map((headCell, index) => (
                      <TableCell
                        key={index}
                        align={headCell.id === "sno" ? "center" : "left"}
                        width={headCell.width}
                        className={classes.thData}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {listSelectedFlag && (
                  <TableBody>
                    {parkingRegisterList.length !== 0 ? (
                      parkingRegisterList.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell align="left" className={classes.tableCell}>
                            {row.firstName + row.lastName
                              ? row.firstName + " " + row.lastName
                              : ""}
                          </TableCell>
                          <TableCell align="left" className={classes.tableCell}>
                            {row.corpEmailId
                              ? row.corpEmailId
                              : row.corpEmpId
                              ? row.corpEmpId
                              : ""}
                          </TableCell>
                          <TableCell align="left" className={classes.tableCell}>
                           {
                            row.userVehicleMappingDtos[0]?.vehicle?.vehicleNumber?row.userVehicleMappingDtos[0].vehicle?.vehicleNumber:""
                           }
                           <br></br>
                           {
                            row.userVehicleMappingDtos[1]?.vehicle?.vehicleNumber?row.userVehicleMappingDtos[1].vehicle?.vehicleNumber:""
                           }
                           </TableCell>
                          <TableCell align="left" className={classes.tableCell}>
                            {row.createdDate
                              ? new Date(row.createdDate).toDateString()
                              : ""}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <Grid item>
                        <Grid container direction="row" justify="center">
                          <Typography variant="h5" color="error">
                            {tableDataNotFound}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </TableBody>
                )}
                {!listSelectedFlag && (
                  <TableBody className={classes.noDataTableBody}>
                    <Grid container justify="center" className={classes.noList}>
                      <Grid item style={{ marginBottom: "70%" }}>
                        You don't have any list here.
                      </Grid>
                    </Grid>
                  </TableBody>
                )}
                {responseDataNotFound && (
                  <TableBody className={classes.noDataTableBody}>
                    <Grid container justify="center" className={classes.noList}>
                      <Grid item style={{ marginBottom: "70%" }}>
                        {responseDataNotFound}
                      </Grid>
                    </Grid>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Grid>
          {listSelectedFlag && !responseDataNotFound && (
            <Grid
              container
              direction="row"
              justify="flex-end"
              className={classes.paginationOuterGrid}
            >
              <Grid item className={classes.paginationGrid}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  className={classes.paginationStyle}
                >
                  <Grid item>
                    <Pagination
                      count={totalPages}
                      className={classes.root}
                      size="small"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!listSelectedFlag && (
            <Grid
              container
              direction="row"
              justify="flex-end"
              className={classes.paginationOuterGrid}
            ></Grid>
          )}
          {responseDataNotFound && (
            <Grid
              container
              direction="row"
              justify="flex-end"
              className={classes.paginationOuterGrid}
            ></Grid>
          )}
        </>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="customized-dialog-title"
          PaperProps={{
            style: {
              paddingTop: matchesXS ? "0.5em" : matchesMD ? "1em" : "2em",
              paddingBottom: matchesXS ? "0.5em" : matchesMD ? "1em" : "2em",
              paddingRight: matchesXS ? "0.5em" : matchesMD ? "1em" : "2em",
              paddingLeft: matchesXS ? "0.5em" : matchesMD ? "1em" : "2em",
            },
          }}
          className={classes.dialog}
        >
          <Grid item>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={() => setOpen(false)}
              >
                <Typography variant="h5">
                  <WarningSharpIcon
                    className={classes.warningIcon}
                  ></WarningSharpIcon>
                  Warning
                </Typography>
              </DialogTitle>
            </Grid>
          </Grid>
          <DialogContent>
            <Grid item>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="p" color="primary">
                    Reports contain personal data. Any Unauthorized sharing of
                    Reports is Prohibited
                  </Typography>
                </Grid>
                <Grid item className={classes.downloadReportButtonGrid}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      reportDownload("EXCEL");
                      setOpen(false);
                    }}
                  >
                    Agree & Download Report
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
}
//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  dispatch,
});
//method to connect react with redux
export default withRouter(connect(null, mapDispatchToProps)(RegisterEndUser));
