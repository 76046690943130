// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import WarningSharpIcon from "@material-ui/icons/WarningSharp";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import Loader from "../../../Components/Preloader";
import Pagination from "@material-ui/lab/Pagination";
import pdfImg from "../../../Assets/icons/pdf1.png";
import excelImg from "../../../Assets/icons/excel1.png";
import httpRequest from "../../../Utils/httpRequest";
import moment from "moment";
import { getCookie } from "../../../Utils/cookies";
import UrlConfig from "../../../Constants/apiUrls";
import { parkingspaceHeaderCell } from "../../../Data/report-page";
import ParkingSpaceUseStyles from "./styles";

//@component
//Parking spaces report
function ReportsParkingSpace(props) {
  const classes = ParkingSpaceUseStyles();
  const [parkingSpaceList, setParkingSpaceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableDataNotFound] = useState("");
  const [responseDataNotFound, setResponseDataNotFound] = useState("");
  const [curPage, setCurPage] = useState(1);
  const [currentPage] = useState(1);
  const [postsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [formatType, setFormatType] = useState("");
  const [totalPages, setTotalPages] = useState(null);
  const [listSelectedFlag, setListSelectedFlag] = useState(false);
  const [noData, setnoData] = useState("");
  //style for dialog
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  //method for dialog
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  //api call to generate reports
  const generateReportHandler = () => {
    setIsLoading(true);
    setListSelectedFlag(true);
    httpRequest.get(
      "reportParkingSpace",
      {
        "Content-Type": "application/json",
      },
      successCallbackReport,
      errorCallback,
      {}
    );
  };
  //success callback for report
  const successCallbackReport = (jsonResponse) => {
    setIsLoading(false);
    setResponseDataNotFound("");
    if (jsonResponse.data?.page?.totalPages < 1) {
      setListSelectedFlag(false);
      setParkingSpaceList([]);
      setnoData("data not found");
    }
    if (
      jsonResponse.data?.page?.totalPages > 0 &&
      jsonResponse.data?._embedded?.results
    ) {
      setParkingSpaceList(jsonResponse.data._embedded.results);
      setTotalPages(jsonResponse.data.page.totalPages);
      setResponseDataNotFound("");
      setnoData("data found");
    }
  };
  //error callback
  const errorCallback = (error) => {
    setIsLoading(false);
    if (error.message !== "Internal Server Error, Please try again later") {
      // any error message
    }
    if (error) {
      setResponseDataNotFound(
        "Data Not Found, Please Refine selection above !"
      );
    }
  };
  //to set array reference connect for pagination table
  const indexOfLastPage = currentPage * postsPerPage;
  const indexOfFirstPage = indexOfLastPage - postsPerPage;
  const currentPost = parkingSpaceList.slice(indexOfFirstPage, indexOfLastPage);
  //api  call for excel/pdf download
  const reportDownload = () => {
    httpRequest.get(
      "reportParkingSpace",
      {
        "Content-Type": "application/json",
      },
      successCallbackReportCheck,
      errorCallbackCheck,
      {}
    );
  };
  //to check if there is length in response and then conditionally download
  const successCallbackReportCheck = (jsonResponse) => {
    if (jsonResponse.data) {
      if (jsonResponse.data._embedded.results) {
        axios({
          url: UrlConfig("reportParkingSpaceDownload", {
            formatType: formatType,
          }),
          method: "GET",
          responseType: "blob",
          headers: {
            realmname: getCookie('realmName'),
            Authorization: "bearer " + getCookie("auth"),
          },
        })
          .then((response) => {
            let fileName = response.headers["content-disposition"].split(
              "filename="
            )[1];
            let fileType = response.headers["content-type"];
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(
                new Blob([response.data], {
                  type: fileType,
                }),
                fileName
              );
            } else {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();
              link.remove();
            }
          })
          .catch(() => {});
      }
    }
  };
  //error callback
  const errorCallbackCheck = (error) => {
    setIsLoading(false);
    if (error.message !== "Internal Server Error, Please try again later") {
      // any error message
    }
    if (error) {
      setResponseDataNotFound("Data Not Found, Unable to download !");
    }
  };
  //function to open excel popup
  const excelPopup = (value) => {
    setOpen(true);
    setFormatType(value);
  };
  //updating breadcrumb and list function call
  useEffect(() => {
    props.dispatch(updateBreadcrumbs("reportsParkingSpace"));
  }, []);
  //Pagination change handle for next/previous/click on Page Number
  const handleChange = (event, value) => {
    setCurPage(value);
  };

  return (
    <>
      {isLoading && <Loader />}
      <Grid container direction="column">
        <Grid item style={{marginTop:'1%'}}>
          <Grid
            item
            container
            direction="row"
            style={{ alignItems: "center" }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            >
            <Grid item xs={10} sm={10} md={10} lg={10} >
              <button
                type="button"
                className={classes.generateReportButton}
                onClick={generateReportHandler}
              >
                Generate My Parking Spaces Report
              </button>
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={1}>
              {/* <IconButton onClick={() => excelPopup("PDF")}>
                <img
                  className={classes.reportExportImage}
                  alt="pdf"
                  src={pdfImg}
                />
              </IconButton> */}
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} >
              <IconButton
                className={classes.downloadButton}
                onClick={() => excelPopup("EXCEL")}
              >
                <img
                  className={classes.reportExportImage}
                  alt="excel"
                  src={excelImg}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <>
          <Grid
            container
            direction="row"
            justify="center"
            className={classes.tableGrid}
          >
            <TableContainer className={classes.paper} component={Paper}>
              <Table className={classes.table} aria-label="parking space table">
                <TableHead>
                  <TableRow>
                    {parkingspaceHeaderCell.map((headCell, index) => (
                      <TableCell
                        key={index}
                        align="left"
                        width={headCell.width}
                        className={classes.thData}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {listSelectedFlag && (
                  <TableBody>
                    {currentPost.length !== 0 ? (
                      currentPost.map((row, index) => {
                        let totalSlots = 0;
                        let Statuscolor =
                          row.status.displayName === "Deactivated"
                            ? "brown"
                            : row.status.color;
                        totalSlots = row.vehicleTypeInfo.reduce(
                          (a, b) => a + (b["totalSlots"] || 0),
                          0
                        );
                        return (
                          <TableRow key={index}>
                            <TableCell
                              className={classes.tableCell}
                              align="center"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              align="left"
                            >
                              {row.name ? row.name : "--"}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              align="left"
                              style={{ width: "25%" }}
                            >
                              {row.addressDetails
                                ? row.addressDetails.addressLine1
                                : "--"}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              align="left"
                            >
                              {totalSlots ? totalSlots : "--"}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              align="left"
                            >
                              {row.parkingBusinessType
                                ? row.parkingBusinessType.name
                                : "--"}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              align="left"
                            >
                              {row.status
                                ? row.status.displayName === "Request Rejected"
                                  ? moment(
                                      row.requestRejectedDate
                                        ? row.requestRejectedDate
                                        : "",
                                      "x"
                                    ).format("DD MMM YYYY hh:mm a")
                                  : row.status.displayName === "Approved"
                                  ? moment(
                                      row.requestApprovedDate
                                        ? row.requestApprovedDate
                                        : "",
                                      "x"
                                    ).format("DD MMM YYYY hh:mm a")
                                  : ""
                                : ""}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              align="center"
                              style={{ color: Statuscolor }}
                            >
                              {row.status ? row.status.displayName : "--"}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <Grid item>
                        <Grid container direction="row" justify="center">
                          <Typography variant="h5" color="error">
                            {tableDataNotFound}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </TableBody>
                )}
                {!listSelectedFlag && (
                  <TableBody className={classes.noDataTableBody}>
                    <Grid container justify="center" className={classes.noList}>
                      <Grid item style={{ marginBottom: "70%" }}>
                        {/* You don't have any list here. */}
                        {noData === ""
                          ? "Click on Generate My Parking Spaces Report"
                          : noData === "data not found"
                          ? "data not found"
                          : ""}
                      </Grid>
                    </Grid>
                  </TableBody>
                )}
                {responseDataNotFound && (
                  <TableBody className={classes.noDataTableBody}>
                    <Grid container justify="center" className={classes.noList}>
                      <Grid item style={{ marginBottom: "70%" }}>
                        {responseDataNotFound}
                      </Grid>
                    </Grid>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Grid>
          {listSelectedFlag && !responseDataNotFound && (
            <Grid
              container
              direction="row"
              justify="flex-end"
              className={classes.paginationOuterGrid}
            >
              <Grid item className={classes.paginationGrid}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  className={classes.paginationStyle}
                >
                  <Grid item>
                    <Pagination
                      count={totalPages}
                      className={classes.root}
                      size="small"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!listSelectedFlag && (
            <Grid
              container
              direction="row"
              justify="flex-end"
              className={classes.paginationOuterGrid}
            ></Grid>
          )}
          {responseDataNotFound && (
            <Grid
              container
              direction="row"
              justify="flex-end"
              className={classes.paginationOuterGrid}
            ></Grid>
          )}
        </>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="customized-dialog-title"
          PaperProps={{
            style: {
              padding: "2%",
            },
          }}
          className={classes.dialogStyle}
        >
          <Grid item>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={() => setOpen(false)}
              >
                <Typography style={{ cursor: "pointer" }} variant="h5">
                  <WarningSharpIcon
                    style={{ verticalAlign: "middle" }}
                  ></WarningSharpIcon>{" "}
                  Warning
                </Typography>
              </DialogTitle>
            </Grid>
          </Grid>
          <DialogContent>
            <Grid item>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    style={{ cursor: "pointer" }}
                    variant="p"
                    color="primary"
                  >
                    Reports contain personal data. Any Unauthorized sharing of
                    Reports is Prohibited
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: "1em" }}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      reportDownload("EXCEL");
                      setOpen(false);
                    }}
                  >
                    Agree & Download Report
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
}
//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  dispatch,
});
//method to connect react with redux
export default withRouter(
  connect(null, mapDispatchToProps)(ReportsParkingSpace)
);
