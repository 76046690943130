import React,{useEffect,useState} from 'react'

const Timer=()=>{
    const [min,setmin]=useState(1)
    const [sec,setsec]=useState(29)
    let interval;
    useEffect(()=>{
    

    interval=setInterval(()=>{

if(sec>1)
{
    setsec(sec-1)
}
else if(min>0)
{
    setmin(min-1)
    setsec(59)
}

        },1000)


    },
   
    )

    useEffect(()=>{
        return ()=>{
            console.log('clear')
            clearInterval(interval)
        }
    })
    useEffect(()=>{
if(sec==0 && min==0)
clearInterval(interval)
    },[sec,min])
    return(<>
    <p><span style={{color:Number(min)<5?'red':'black'}}>{min<10?`0${min}`:min}</span>:<span style={{color:Number(min)<5?'red':'black'}}>{sec<10?`0${sec}`:sec}</span></p>
    </>)

}

export default Timer