// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  updateBreadcrumbs,
  updateToasterConfig,
} from "../../../redux/modules/app/actions";
import listviewUseStyles from "./style";
import Loader from "../../../Components/Preloader/index";
import httpRequest from "../../../Utils/httpRequest";
import { tempAccessListHeaderCell } from "../../../Data/report-page";
import Pagination from "@material-ui/lab/Pagination";
import deleteIcon from "../../../Assets/icons/deleteIcon.png";

import EditIcon from "../../../Assets/Images/edit.png";
import synced from "../../../Assets/Images/live.png";
import notSynced from "../../../Assets/Images/Non-Sync.png";
import { withStyles } from "@material-ui/core";
import { getCookie } from "../../../Utils/cookies";
import "./style.scss";

//@component
//TempAccessListView
function TempAccessListView(props) {
  const classes = listviewUseStyles();

  const [viewList, setViewList] = useState([]);
  const [parkList, setParkList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //const [tableDataNotFound, setTableDataNotFound] = useState("");
  const [responseDataNotFound, setResponseDataNotFound] = useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("datetime");
  const [pageNumber, setPagenumber] = useState(
    props.location.state?.back === "true" ? props.location.state?.loadpage : 0
  );
  const [searchKey, setSearchKey] = useState(
    props.location.state?.searchKey ? props.location.state?.searchKey : ""
  );
  const [searchFlag, setSearchFlag] = useState(false);
  const [postsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  //const [internetFlag, setInternetFlag] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState([]);
  const [dialogTitle, setDialogTitle] = useState("");
  const [responseType, setresponseType] = useState("");
  const [falseList, setfalseList] = useState(0);
  const [syncpass, setsyncpass] = useState(0);
  const [syncfail, setsyncfail] = useState(0);
  //const [noSearch, setNosearch] = useState(false);

  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const prevCount = usePrevious(searchKey)

  const inputRef = useRef();

  //mui custom styling
  const DialogActions = withStyles(() => ({
    root: {
      padding: "8px 0px !important",
    },
  }))(MuiDialogActions);

  //custom hook to get previous value
  // function usePrevious(value) {
  //   const ref = useRef();
  //   useEffect(() => {
  //     ref.current = value; //assign the value of ref to the argument
  //   },[value]); //this code will run when the value of 'value' changes
  //   return ref.current; //in the end, return the current ref value.
  // }

  // while component is loading this api is called
  const getTempAccessList = (page) => {
    //console.log("previous value", searchKey, prevCount)
    httpRequest.get(
      "tempAccessListView",
      {
        "Content-Type": "application/json",
      },
      successCallbackList,
      errorCallbackList,
      {
        page: page,
        size: postsPerPage,
        searchData: searchKey,
      }
    );
  };
  // success callback for get list
  const successCallbackList = (jsonResponse) => {
    if (jsonResponse.data?._embedded.results.length === 0) {
      //setTableDataNotFound("No data here");
      setResponseDataNotFound("No data here");
      //setInternetFlag(true);
      setfalseList(0);
      setIsLoading(false);
      setPagenumber(0);
      setTotalPages(1);
    }
    //setTableDataNotFound("");
    setPagenumber(jsonResponse.data.page.number);
    setViewList(jsonResponse.data?._embedded.results);
    setTotalPages(jsonResponse.data.page.totalPages);
    // setTotalElements(jsonResponse.data.page.totalElements);
    //setInternetFlag(false);
    setIsLoading(false);
    setfalseList(0);
    // setNosearch(false)
  };

  // error callback for get list
  console.log(responseType);
  const errorCallbackList = (error) => {
    if (error) {
      setIsLoading(false);
      setDialogOpen(true);
      setResponseDataNotFound("No data here");
      setresponseType("searchFail");
      //setInternetFlag(false);
    }
  };
  // callback api call when pagination button is clicked
  const pageHandler = (number) => {
    setIsLoading(true);
    let pagenumber = number || 0;
    //console.log("page number", pagenumber)

    httpRequest.get(
      "tempAccessListView",
      {
        "Content-Type": "application/json",
      },
      successCallbackList,
      errorCallbackList,
      {
        page: pagenumber,
        size: postsPerPage,
        searchData: searchKey,
      }
    );
  };
  // when pagination button is clicked here page number is set and api callback is triggered
  const handleChange = (event, value) => {
    // if (noSearch){
    //   setDialogOpen(true)
    //   setresponseType('searchFail')

    // }
    let currpage;
    currpage = value - 1;
    pageHandler(currpage);
  };
  // when component loading it updates breadcrumb and get list callback
  useEffect(() => {
    setIsLoading(true);
    props.dispatch(updateBreadcrumbs("tempaccesslistview"));

    getTempAccessList(pageNumber);
  }, []);

  useEffect(() => {
    inputRef.current.focus();
  });

  //function for table header arrow functionality and datestamp sort api call
  function EnhancedTableHead(props) {
    const { classes } = props;
    // const createSortHandler = (property) => (event) => {
    //   onRequestSort(event, property);
    // };
    return (
      <TableHead>
        <TableRow>
          {tempAccessListHeaderCell.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={
                headCell.id === "sno" ||
                headCell.id === "syncstatus" ||
                headCell.id === "actions"
                  ? "center"
                  : "left"
              }
              width={headCell.width}
              className={classes.thData}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  //propTypes for table header arrow function
  EnhancedTableHead.propTypes = {
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  //table header arrow sub-function
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  // on clicking upload user button it redirects to upload page
  const uploadHandler = () => {
    props.history.push({
      pathname: "/portal/tempaccessbulkupload",
    });
  };
  // const syncHandler = () => {
  //   setIsLoading(true);
  //   httpRequest.get(
  //     "tempAccessListViewSync",
  //     {
  //       "Content-Type": "application/json",
  //     },
  //     successCallbacksynclist,
  //     errorCallbackList,
  //     {}
  //   );
  // };
  const manualSync = (data) => {
    httpRequest.patch(
      "manualtempAccessSync",
      data,
      {},
      successCallbacksync,
      errorCallbacksync,
      {}
    );
  };
  const successCallbacksync = (jsonResponse) => {
    setIsLoading(false);
    setDialogOpen(true);
    setresponseType("success");
    setsyncpass(jsonResponse.data?.noOfVehicleSynced);
    setsyncfail(jsonResponse.data?.noOfVehicleNotSynced);
  };
  const errorCallbacksync = () => {
    setIsLoading(false);
    setDialogOpen(true);
    setresponseType("fail");
  };
  // const successCallbacksynclist = (jsonResponse) => {
  //   let data = [];
  //   let count = 0;
  //   jsonResponse.data._embedded.results.map((vehicles) => {
  //     if (vehicles.syncStatus === false) {
  //       count = count + 1;
  //       data.push({
  //         // "accessControlId": vehicles.accessControlId,
  //         vehicleNumber: vehicles.vehicleNumber,
  //       });
  //     }
  //   });
  //   data.toString();
  //   //console.log(typeof(data))
  //   setfalseList(count);
  //   manualSync(data);
  // };
  //close dialog and refresh
  const closeRefresh = () => {
    setIsLoading(false);
    getTempAccessList(0);
    setresponseType("");
    setDialogOpen(false);
  };

  // when we click on confirm button in delete popup
  const submitDetails = () => {
    setIsLoading(true);
    httpRequest.deleteData(
      "deleteTempAccessUser",
      {
        parkingIds: deleteId,

        vehicleNumber: dialogTitle,
        adminRemarks: "manual deletion of Vehicle access",
      },
      {
        "Content-Type": "application/json",
      },
      successCallbackDelete,
      errorCallbackDelete,
      {}
    );
    setDialogOpen(false);
  };
  // user delete success callback
  const successCallbackDelete = (jsonResponse) => {
    if (jsonResponse) {
      setViewList([]);

      setDeleteId([]);

      // setresponseType("searchFail");
      // console.log(viewList);

      props.dispatch(
        updateToasterConfig({
          show: true,
          message: `Vehicle Access removed for the selected parking spaces`,
          color: "green",
        })
      );

      if (searchKey === "") {
        getTempAccessList(pageNumber);
      }

      if (searchKey != "") {
        setIsLoading(false);
      }
    }
  };
  // user delete error callback
  const errorCallbackDelete = (error) => {
    setIsLoading(false);
    setDeleteId([]);
    if (error) {
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: error.error_description || error.message || error.error,
          color: "red",
        })
      );
    }
  };
  // when we click on delete icon
  const deleteHandler = (id, vehicle) => {
    // setDeleteId(id);

    //setDialogRole(orgname)
    setDialogTitle(vehicle);
    //setDialogEmail(email);
    setIsLoading(true);

    // if (props.location.state) {
    httpRequest.get(
      "viewtempaccountdetails",

      {
        "Content-Type": "application/json",
      },
      successCallbackdetails,
      errorCallbackDetails,
      {
        vehicle: vehicle,
      }
    );
  };

  //getting parking list
  const successCallbackdetails = async (jsonResponse) => {
    setIsLoading(false);
    setDialogOpen(true);
    setParkList(jsonResponse.data?.accessedParkingList);
    // console.log(jsonResponse.data?.accessedParkingList)
    // getTempAccessList(jsonResponse.data?.accessedParkingList);
  };

  //getting parking list error
  const errorCallbackDetails = (error) => {
    setIsLoading(false);
    if (error) {
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: error.error_description || error.message || error.error,
          color: "red",
        })
      );
    }
  };
  //checkbox to select parking handler

  const deleteParkingHandler = (id) => {
    if (deleteId.includes(id)) {
      let index = deleteId.indexOf(id);
      let numberOfElementToRemove = 1;
      if (index !== -1) {
        deleteId.splice(index, numberOfElementToRemove);
      }
    } else deleteId.push(id);
  };

  //function to capture and pass user id between components
  const viewHandler = (data1, data2) => {
    console.log("viewid", data1);
    props.history.push({
      pathname: "/portal/viewtempaccountdetails",
      state: {
        vehicleNumber: data1,
        userId: data2,
        page: pageNumber,
        searchKey: searchKey,
        //  currentPage: currentPage
      },
    });
  };

  //handle search change
  const handleSearchKeyChange = (e) => {
    setSearchKey(e.target.value);
  };

  useEffect(() => {
    getTempAccessList(pageNumber);
  }, [searchFlag]);

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  //handle search
  const handleSearchKey = () => {
    const email = validateEmail(searchKey);
    console.log(email);
    email === true
      ? setSearchKey(searchKey.toLowerCase())
      : setSearchKey(searchKey.toUpperCase());
    //setSearchKey(search)
    setIsLoading(true);
    setPagenumber(0);
    setSearchFlag(!searchFlag);
  };

  //clear search
  const clearSearch = () => {
    setIsLoading(true);
    setSearchKey("");
    setPagenumber(0);
    setSearchFlag(!searchFlag);
  };

  //close search fail dialog
  const closeSearchFail = () => {
    setIsLoading(true);
    setDialogOpen(false);
    setResponseDataNotFound("");
    setresponseType("");
    setSearchKey("");
    inputRef.current.focus();
    setSearchFlag(!searchFlag);
  };

  return (
    <>
      {isLoading && <Loader />}
      <ClickAwayListener>
        <Grid container direction="column" className={classes.container}>
          <ClickAwayListener>
            <Grid
              container
              direction="row"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.header}
              style={{marginTop:'1%'}}
            >
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <div className="ParkingOverview-navbar__wrapper_nav_left">
                  <div className="ParkingOverview-navbar__wrapper_nav_left_searchicon sIconC">
                    {(searchKey === "" || searchKey?.length < 3) && (
                      <SearchIcon />
                    )}
                  </div>
                  <div className="ParkingOverview-navbar__wrapper_nav_left_search">
                    <input
                      placeholder="Search with Organization or Email Id or Vehicle Number"
                      value={searchKey}
                      onChange={handleSearchKeyChange}
                      ref={inputRef}
                      disabled={responseDataNotFound !== "" ? true : false}
                    />
                    {searchKey?.length > 2 && (
                      <p
                        className={
                          searchKey === "" ? "editcross" : "input__cross"
                        }
                        onClick={handleSearchKey}
                      >
                        <SearchIcon />
                      </p>
                    )}
                    {searchKey !== "" && (
                      <p className={"input__cross_x"} onClick={clearSearch}>
                        <ClearIcon />
                      </p>
                    )}
                  </div>
                </div>
              </Grid>

              <Grid className="action-buttons" style={{display:'flex',justifyContent:'flex-end',}} item xs={4} sm={4} md={4} lg={4}>
                <button
                  type="button"
                  className={classes.createuserButton}
                  onClick={uploadHandler}
                >
                  Upload Vehicle(s) +
                </button>

                
              </Grid>
              {searchKey !== "" && searchKey?.length < 3 && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <p className={classes.searchVal}>
                    Enter at least 3 characters to initiate search
                  </p>
                </Grid>
              )}
            </Grid>
          </ClickAwayListener>
          <React.Fragment>
            <Grid item className={classes.aboveTableGrid}>
              <Grid
                container
                direction="row"
                justify="center"
                className={classes.tableGrid}
              >
                <TableContainer className={classes.paper} component={Paper}>
                  <Table className={classes.table} aria-label="View table">
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />

                    <TableBody>
                      {responseDataNotFound === "" ? (
                        viewList.map((row, index) => {
                          let no = index;
                          function formatNo(no) {
                            return no < 10
                              ? pageNumber + "" + no
                              : (pageNumber + 1) * no;
                          }
                          return (
                            <TableRow key={index}>
                              <TableCell
                                className={classes.tableCell}
                                align="center"
                              >
                                {formatNo(no + 1)}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="left"
                              >
                                {row?.organizaionName
                                  ? row?.organizaionName
                                  : "--"}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="left"
                              >
                                {row?.emailId ? row?.emailId : "--"}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="left"
                              >
                                <grid>
                                  {row?.vehicleNumber
                                    ? row?.vehicleNumber
                                    : "--"}
                                </grid>

                                <br />
                                <grid>
                                  {row?.vehicleType?.name
                                    ? row?.vehicleType.name
                                    : "--"}
                                </grid>
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="left"
                              >
                                {row?.rfId ? row?.rfId : "--"}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="left"
                              >
                                {row?.accessType?.accessTypes
                                  ? row?.accessType.accessTypes
                                  : "--"}
                              </TableCell>

                              <TableCell
                                className={classes.tableCell}
                                style={{ textAlign: "center" }}
                                align="left"
                              >
                                {row?.syncStatus ? (
                                  <img
                                    alt="synced"
                                    src={synced}
                                    className={classes.edit_icon}
                                  />
                                ) : (
                                  <img
                                    alt="not_synced"
                                    src={notSynced}
                                    className={classes.edit_icon}
                                  />
                                )}
                              </TableCell>
                              <TableCell className={classes.viewTableCell}>
                                <Button
                                  className={classes.viewButton}
                                  onClick={() =>
                                    viewHandler(
                                      row.vehicleNumber
                                        ? row.vehicleNumber
                                        : "",
                                      row?.userId
                                    )
                                  }
                                >
                                  View
                                </Button>
                                <img
                                  alt="delete_icon"
                                  src={deleteIcon}
                                  className={classes.delete_icon}
                                  onClick={() =>
                                    deleteHandler(
                                      row?.accessControlId,
                                      row?.vehicleNumber
                                    )
                                  }
                                />
                                {row?.userId === getCookie("userId") && (
                                  <img
                                    alt="edit_icon"
                                    src={EditIcon}
                                    // style={{display:'none'}}
                                    className={classes.edit_icon}
                                    onClick={() => {
                                      props.history.push({
                                        pathname: "/portal/tempaccessedit",
                                        state: {
                                          accessControlId: row.vehicleNumber
                                            ? row.vehicleNumber
                                            : "",
                                          page: pageNumber,
                                          searchKey: searchKey,
                                        },
                                      });
                                    }}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableBody className={classes.noDataTableBody}>
                          <Grid
                            container
                            justify="center"
                            className={classes.noList}
                          >
                            <Grid item style={{ marginBottom: "70%" }}>
                              {searchKey === "" ? "No records added" : ""}
                            </Grid>
                          </Grid>
                        </TableBody>

                        // <Dialog
                        //   open={dialogOpen}
                        //   onClose={() => {
                        //     setDialogOpen(false);
                        //     setresponseType("");
                        //   }}
                        //   PaperProps={{
                        //     style: {
                        //       padding: "1% 2%",
                        //       width: "425px",
                        //     },
                        //   }}
                        //   className={classes.dialog}
                        // >
                        //   <MuiDialogTitle
                        //     style={{
                        //       paddingBottom: "8px",
                        //       paddingTop: "2px",
                        //       paddingLeft: "2px",
                        //       paddingRight: "2px",
                        //     }}
                        //   >
                        //     <Grid
                        //       container
                        //       direction="row"
                        //       justify="space-between"
                        //     >
                        //       <Grid item>
                        //         <span className={classes.dialogHeader}>
                        //           Alert
                        //         </span>
                        //       </Grid>
                        //     </Grid>
                        //   </MuiDialogTitle>
                        //   <Divider />
                        //   <DialogContent
                        //     style={{ paddingLeft: "0px", paddingRight: "0px" }}
                        //   >
                        //     <Grid item>
                        //       <Grid
                        //         container
                        //         direction="column"
                        //         justify="center"
                        //       >
                        //         <Grid item>
                        //           <Typography>
                        //             We can't find any results matching your
                        //             search. Kindly try with a different keyword.
                        //           </Typography>
                        //         </Grid>
                        //       </Grid>
                        //     </Grid>
                        //   </DialogContent>
                        //   <DialogActions>
                        //     <Grid item>
                        //       <Grid
                        //         container
                        //         direction="row"
                        //         justify="flex-end"
                        //         className={classes.dialogActionGrid}
                        //       >
                        //         <Grid item className={classes.dialogCancelGrid}>
                        //           <button
                        //             type="button"
                        //             onClick={closeSearchFail}
                        //             className={classes.dialogConfirm}
                        //           >
                        //             OK
                        //           </button>
                        //         </Grid>
                        //       </Grid>
                        //     </Grid>
                        //   </DialogActions>
                        // </Dialog>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-end"
              className={classes.paginationOuterGrid}
            >
              <Grid item className={classes.paginationGrid}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  className={classes.paginationStyle}
                >
                  <Grid item>
                    <Pagination
                      count={totalPages}
                      page={pageNumber + 1}
                      className={classes.root}
                      size="small"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>

          {responseType === "success" && (
            <Dialog
              open={dialogOpen}
              onClose={() => {
                setDialogOpen(false);
                setresponseType("");
              }}
              PaperProps={{
                style: {
                  padding: "1.5% 3%",
                },
              }}
              className={classes.dialog}
            >
              <MuiDialogTitle
                style={{
                  paddingBottom: "8px",
                  paddingTop: "2px",
                  paddingLeft: "2px",
                  paddingRight: "2px",
                }}
              >
                <Grid container direction="row" justify="space-between">
                  <Grid item>
                    <span className={classes.dialogHeader}>
                      Vehicle Access Data Manual Sync
                    </span>
                  </Grid>
                </Grid>
              </MuiDialogTitle>
              <Divider />
              <DialogContent
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <Grid container direction="column" justify="center">
                  {falseList === 0 && (
                    <Grid item>
                      <p>
                        <span className={classes.dialogSubTextPass}>
                          All Vehicle's data are Synced with Parking Space
                          Gateway
                        </span>
                      </p>
                    </Grid>
                  )}
                  {falseList > 0 && (
                    <>
                      <Grid item>
                        <p>
                          <span className={classes.dialogSubTitle}>
                            Number of vehicles Failed to Sync:
                          </span>{" "}
                          <span className={classes.dialogSubTextFail}>
                            {syncfail}
                          </span>
                        </p>
                      </Grid>
                      <Grid item>
                        <p>
                          <span className={classes.dialogSubTitle}>
                            Number of Vehicles Synced Successfully:
                          </span>{" "}
                          <span className={classes.dialogSubTextPass}>
                            {syncpass}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    className={classes.dialogActionGrid}
                  >
                    {falseList === 0 && (
                      <Grid item className={classes.dialogCancelGrid}>
                        <button
                          type="button"
                          onClick={() => {
                            setDialogOpen(false);
                            setresponseType("");
                          }}
                          className={classes.dialogConfirm}
                        >
                          OK
                        </button>
                      </Grid>
                    )}
                    {falseList > 0 && (
                      <Grid item className={classes.dialogCancelGrid}>
                        <button
                          type="button"
                          onClick={() => {
                            closeRefresh();
                          }}
                          className={classes.dialogConfirm}
                        >
                          OK
                        </button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          )}
          {responseType === "fail" && (
            <Dialog
              open={dialogOpen}
              onClose={() => {
                setDialogOpen(false);
                setresponseType("");
              }}
              PaperProps={{
                style: {
                  padding: "1.5% 3%",
                },
              }}
              className={classes.dialog}
            >
              <MuiDialogTitle
                style={{
                  paddingBottom: "8px",
                  paddingTop: "2px",
                  paddingLeft: "2px",
                  paddingRight: "2px",
                }}
              >
                <Grid container direction="row" justify="space-between">
                  <Grid item>
                    <span className={classes.dialogHeader}>
                      Vehicle Access Manual Sync
                    </span>
                  </Grid>
                </Grid>
              </MuiDialogTitle>
              <Divider />
              <DialogContent
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <Grid item>
                  <Grid container direction="column" justify="center">
                    <Grid item>
                      <Typography className={classes.dialogSubHeaderError}>
                        Vehicle Access Data Sync Failed, Please try Again
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    className={classes.dialogActionGrid}
                  >
                    <Grid item className={classes.dialogCancelGrid}>
                      <button
                        type="button"
                        onClick={() => {
                          setDialogOpen(false);
                          setresponseType("");
                        }}
                        className={classes.dialogConfirm}
                      >
                        OK
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          )}
          {dialogOpen && responseType === "" && (
            <Dialog
              open={dialogOpen}
              onClose={() => {
                setDialogOpen(false);
                setresponseType("");
                setDeleteId([]);
              }}
              PaperProps={{
                style: {
                  padding: "1.5% 3%",
                },
              }}
              className={classes.dialog}
            >
              <MuiDialogTitle
                style={{
                  paddingBottom: "8px",
                  paddingTop: "2px",
                  paddingLeft: "2px",
                  paddingRight: "2px",
                }}
              >
                <Grid container direction="row" justify="space-between">
                  <Grid item>
                    <span className={classes.dialogHeader}>
                      Remove Vehicle Access
                    </span>
                  </Grid>
                </Grid>
              </MuiDialogTitle>
              <Divider />
              <DialogContent
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <Grid item>
                  <Grid container direction="column" justify="center">
                    <Grid item>
                      <Typography className={classes.dialogSubHeader}>
                        Select the parking spaces to remove Vehicle access
                      </Typography>
                    </Grid>
                    {parkList.map((item) => {
                      return (
                        <Grid container>
                          <Checkbox
                            color="default"
                            size="small"
                            type="checkbox"
                            data-target="filter"
                            onChange={() =>
                              deleteParkingHandler(item?.parkingId)
                            }
                          />
                          <span
                            className={classes.dialogSubTitle}
                            style={{ alignSelf: "center" }}
                          >
                            {item.parkingName}
                          </span>
                          <br />
                          <span className={classes.dialogAddress}>
                            {"(" + item.parkingAddress + ".)"}
                          </span>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    className={classes.dialogActionGrid}
                  >
                    <Grid item className={classes.dialogCancelGrid}>
                      <button
                        type="button"
                        onClick={() => {
                          setDialogOpen(false);
                          setDeleteId([]);
                        }}
                        className={classes.dialogCancel}
                      >
                        Cancel
                      </button>
                    </Grid>
                    <Grid item className={classes.dialogConfirmGrid}>
                      <button
                        type="button"
                        disabled={deleteId.length === 0}
                        style={{
                          backgroundColor:
                            deleteId.length === 0 ? "#A2A2A2" : "#03A9F3",
                        }}
                        className={classes.dialogConfirm}
                        onClick={submitDetails}
                      >
                        Confirm
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          )}
          {responseType === "searchFail" &&
            (viewList.length !== 0 || searchKey !== "") && (
              <Dialog
                open={dialogOpen}
                onClose={() => {
                  setDialogOpen(false);
                  setresponseType("");
                }}
                PaperProps={{
                  style: {
                    padding: "1% 2%",
                    width: "425px",
                  },
                }}
                className={classes.dialog}
              >
                <MuiDialogTitle
                  style={{
                    paddingBottom: "8px",
                    paddingTop: "2px",
                    paddingLeft: "2px",
                    paddingRight: "2px",
                  }}
                >
                  <Grid container direction="row" justify="space-between">
                    <Grid item>
                      <span className={classes.dialogHeader}>Alert</span>
                    </Grid>
                  </Grid>
                </MuiDialogTitle>
                <Divider />
                <DialogContent
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <Grid item>
                    <Grid container direction="column" justify="center">
                      <Grid item>
                        <Typography>
                          We can't find any results matching your search. Kindly
                          try with a different keyword.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      className={classes.dialogActionGrid}
                    >
                      <Grid item className={classes.dialogCancelGrid}>
                        <button
                          type="button"
                          onClick={closeSearchFail}
                          className={classes.dialogConfirm}
                        >
                          OK
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Dialog>
            )}
        </Grid>
      </ClickAwayListener>
    </>
  );
}
//  redux state method
// const mapStateToProps = (state) => {
//   return {
//       pageRedirect : state.app.pageRedirect
//   }
// }

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  dispatch,
});

export default withRouter(
  connect(null, mapDispatchToProps)(TempAccessListView)
);
