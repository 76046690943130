// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import listviewUseStyles from "./style";
import {
  Grid,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Checkbox,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateBreadcrumbs,
  updateToasterConfig,
} from "../../../../../redux/modules/app/actions";
import httpRequest from "../../../../../Utils/httpRequest";
import Typography from "@material-ui/core/Typography";
import "./style.scss";
import Pagination from "@material-ui/lab/Pagination";
import Filtericon from "../../../../../Assets/Images/funnel-hz.png";
import Clickedfilter from "../../../../../Assets/Images/Path 1482.png";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";

//@component
//Slot Count
function BookingsCard(props) {
  const [bookingDetail, setbookingDetail] = useState([]);
  const [showDiv, setShowDiv] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  //const [filterFlag, setFilterFlag] = useState(false);
  const [totalPages, settotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  //const [pageNo, setPageNo] = useState(null);
  const [loading, setloading] = useState(false);

  const classes = listviewUseStyles();

  const startMe = (page) => {
    setloading(true);

    httpRequest.get(
      "viewAllBookings",
      {
        "Content-Type": "application/json",
      },
      UserDataSuccess,
      errorCallback,
      {
        page: page,
        size: 10,
        parkingId: props?.parkingId,
        status: props.parked
          ? "PARKED"
          : "COMPLETED,CANCELLED,CONFIRMED,PARKED",
        // startTime:Date.parse(`${new Date().getMonth() +1}/${new Date().getDate()}/${new Date().getFullYear()} 00:00:00:000`),
        // endTime:Date.parse(`${new Date().getMonth() +1}/${new Date().getDate()}/${new Date().getFullYear()} 23:59:59:999`),
        startTime: Date.parse(moment().startOf("day")),
        endTime: Date.parse(moment().endOf("day")),
        searchData:'',
        sortBy:''

      }
    );
  };

  const UserDataSuccess = (jsonResult) => {
    setloading(false);
    if (jsonResult) {
      if (!jsonResult.data?._embedded?.results) {
        setbookingDetail([]);
      } else {
        setbookingDetail(jsonResult.data?._embedded?.results);
        settotalPages(jsonResult.data?.page?.totalPages);
        setPageNumber(jsonResult.data?.page?.number);
      }
    }
  };

  const errorCallback = (error) => {
    setloading(false);
    if (error) {
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: error.error_description || error.message || error.error,
          color: "red",
        })
      );
    }
  };

  //Pagination change handle for next/previous/click on Page Number
  const handleChange = (event, value) => {
    setPageNumber(value - 1);

    let currpage;
    currpage = value - 1;

    httpRequest.get(
      "viewAllBookings",
      {
        "Content-Type": "application/json",
      },
      UserDataSuccess,
      errorCallback,
      {
        page: currpage,
        size: 10,
        parkingId: props?.parkingId,
        status: props.parked
          ? "PARKED"
          : "COMPLETED,CANCELLED,CONFIRMED,PARKED",
        startTime: Date.parse(moment().startOf("day")),
        endTime: Date.parse(moment().endOf("day")),
      }
    );
  };

  //function for filter
  const filterOptionSetter = () => {
    let toggleShowDiv = !showDiv;
    setShowDiv(toggleShowDiv);
  };
  // Status filter handler function
  const statusFilterHandler = (status) => {
    setFilterValue(status);
    //setFilterFlag(false);
  };

  useEffect(() => {
  let parkingId = props.parkingId;
    // setPageNo(0);
    startMe(0);
    // console.log('dasdadadad',props.parked?'PARKED':'COMPLETED,CANCELLED,CONFIRMED,PARKED')

    // let interval = setInterval(() => {
    //   httpRequest.get(
    //     'bookingsReport',
    //     {
    //       'Content-Type': 'application/json'
    //     },
    //     UserDataSuccess,
    //     errorCallback,
    //     {
    //       fromTime: props.location?.state?.prevDate,
    //       toTime: props.location?.state?.prevDate || new Date(),
    //       page: pageNo,
    //       size: 10,
    //       parkingId: parkingId,

    //     },
    //   )
    // }, 30000);
    // return () => {
    //   clearInterval(interval);
    // }
  }, []);

  return (
    <div className="bookingcard">
      <div className="bookingcard__wrapper">
        <Grid container className="grid-outer-container" spacing={2}>
          <Grid item md={12}>
            <Card raised={true} className="details_card">
              <Grid
                container
                direction="row"
                justify="space-between"
                className="slot-count-header-grid"
              >
                <Grid item className="slot-count-text-grid">
                  <Typography variant="h6">
                    {props.parked ? "Parked Users Details" : "Booking Details"}
                  </Typography>
                </Grid>

                <Grid item className="set-button-grid">
                  {/* <button className='slot-count-set-button'>
                    Set Parking Occupancy
                  </button> */}
                </Grid>
              </Grid>
              <CardContent className="details_info">
                {bookingDetail.length ? (
                  <>
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow className="table-row">
                            <TableCell align="left">S.No</TableCell>
                            <TableCell
                              className="table-row-header"
                              align="left"
                            >
                              Vehicle Number
                            </TableCell>
                            <TableCell
                              className="table-row-header"
                              align="left"
                            >
                              Booking Start Date and Time
                            </TableCell>
                            <TableCell
                              className="table-row-header"
                              align="left"
                            >
                              Booking End Date and Time
                            </TableCell>
                            <TableCell
                              className="table-row-header"
                              align="left"
                            >
                              Booked By
                            </TableCell>
                            <TableCell
                              className="table-row-header"
                              align="left"
                            >
                              Slot No
                            </TableCell>
                            <TableCell
                              className="table-row-header"
                              align="left"
                            >
                              Check-In Time
                            </TableCell>
                            <TableCell
                              className="table-row-header"
                              align="left"
                            >
                              Check-out Time
                            </TableCell>
                            <TableCell
                              className="table-row-header"
                              align="left"
                            >
                              Status
                              <Button
                                style={{ display: "none" }}
                                className={classes.filter_button}
                                onClick={() => filterOptionSetter()}
                              >
                                <img
                                  src={
                                    // Filtericon
                                    filterValue.length
                                      ? Clickedfilter
                                      : Filtericon
                                  }
                                  alt="Filtericon"
                                  className={classes.filter_image}
                                />
                                <Grid
                                  item
                                  container
                                  direction="column"
                                  className={classes.filter_box}
                                  style={{
                                    display: showDiv ? "block" : "none",
                                  }}
                                >
                                  <Grid item className={classes.liGrid}>
                                    <li>
                                      <div className="OperationalTimings__wrapper_form_checkside">
                                        <Checkbox
                                          color="default"
                                          type="checkbox"
                                          id="all"
                                          data-target="filter"
                                          onChange={() =>
                                            statusFilterHandler("")
                                          }
                                          checked={filterValue === ""}
                                        />
                                      </div>
                                      <label className={classes.filterLabel}>
                                        All
                                      </label>
                                    </li>
                                  </Grid>
                                  <Grid item className={classes.liGrid}>
                                    <li>
                                      <div className="OperationalTimings__wrapper_form_checkside">
                                        <Checkbox
                                          color="default"
                                          type="checkbox"
                                          id="all"
                                          data-target="filter"
                                          onChange={() =>
                                            statusFilterHandler("PARKED")
                                          }
                                          checked={filterValue === "PARKED"}
                                        />
                                      </div>
                                      <label className={classes.filterLabel}>
                                        Parked
                                      </label>
                                    </li>
                                  </Grid>
                                  <Grid item className={classes.liGrid}>
                                    <li>
                                      <div className="OperationalTimings__wrapper_form_checkside">
                                        <Checkbox
                                          color="default"
                                          type="checkbox"
                                          id="all"
                                          data-target="filter"
                                          onChange={() =>
                                            statusFilterHandler("APPROVED")
                                          }
                                          checked={filterValue === "APPROVED"}
                                        />
                                      </div>
                                      <label className={classes.filterLabel}>
                                        Confirmed
                                      </label>
                                    </li>
                                  </Grid>
                                  <Grid item className={classes.liGrid}>
                                    <li>
                                      <div className="OperationalTimings__wrapper_form_checkside">
                                        <Checkbox
                                          color="default"
                                          type="checkbox"
                                          id="all"
                                          data-target="filter"
                                          onChange={() =>
                                            statusFilterHandler("CANCELLED")
                                          }
                                          checked={filterValue === "CANCELLED"}
                                        />
                                      </div>
                                      <label className={classes.filterLabel}>
                                        Cancelled
                                      </label>
                                    </li>
                                  </Grid>
                                  <Grid item className={classes.liGrid}>
                                    <li>
                                      <div className="OperationalTimings__wrapper_form_checkside">
                                        <Checkbox
                                          color="default"
                                          type="checkbox"
                                          id="all"
                                          data-target="filter"
                                          onChange={() =>
                                            statusFilterHandler("COMPLETED")
                                          }
                                          checked={filterValue === "COMPLETED"}
                                        />
                                      </div>
                                      <label className={classes.filterLabel}>
                                        Completed
                                      </label>
                                    </li>
                                  </Grid>
                                  <hr className={classes.hrStyling} />
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setShowDiv(false);
                                      startMe(0);
                                    }}
                                    className={classes.applyButtonFilter}
                                  >
                                    Apply
                                  </button>
                                </Grid>
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {bookingDetail.map((item, index) => {
                            let no = index;
                            function formatNo(no) {
                              return no < 10
                                ? pageNumber + "" + no
                                : (pageNumber + 1) * no;
                            }
                            return (
                              <TableRow>
                                <TableCell
                                  align="left"
                                  className="total-slot-table-cell"
                                >
                                  {formatNo(no + 1)}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="total-slot-table-cell"
                                >
                                  {item?.vehicle?.vehicleNumber}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="total-slot-table-cell"
                                >
                                  {moment(item?.startTime).format(
                                    "MMM D, YYYY h:mm A"
                                  )}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="total-slot-table-cell"
                                >
                                  {moment(item?.endTime).format(
                                    "MMM D, YYYY h:mm A"
                                  )}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="total-slot-table-cell"
                                >
                                  {item?.bookedBy ? (
                                    item?.bookedBy?.displayName
                                  ) : (
                                    <span className={classes.nullSpan}>--</span>
                                  )}
                                </TableCell>

                                <TableCell
                                  align="left"
                                  className="total-slot-table-cell"
                                >
                                  {item?.checkInCheckOutDto?.slotNo
                                    ? item?.checkInCheckOutDto?.slotNo
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="total-slot-table-cell"
                                >
                                  {item?.checkInCheckOutDto?.checkInTime
                                    ? moment(
                                        item?.checkInCheckOutDto?.checkInTime
                                      ).format("MMM D, YYYY h:mm A")
                                    : "--"}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="total-slot-table-cell"
                                >
                                  {item?.checkInCheckOutDto?.checkOutTime
                                    ? moment(
                                        item?.checkInCheckOutDto?.checkOutTime
                                      ).format("MMM D, YYYY h:mm A")
                                    : "--"}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="total-slot-table-cell"
                                  style={{ color: item?.bookingStatus?.color }}
                                >
                                  {item?.bookingStatus?.name
                                    .charAt(0)
                                    .toUpperCase() +
                                    item?.bookingStatus?.name
                                      .slice(1)
                                      .toLowerCase()}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      className={classes.paginationOuterGrid}
                    >
                      <Grid item className={classes.paginationGrid}>
                        <Grid
                          container
                          direction="row"
                          justify="flex-end"
                          className={classes.paginationStyle}
                        >
                          <Grid item>
                            <Pagination
                              count={totalPages}
                              className={classes.root}
                              size="small"
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow className="table-row">
                            <TableCell align="left">S.No</TableCell>
                            <TableCell
                              className="table-row-header"
                              align="left"
                            >
                              Vehicle Number
                            </TableCell>
                            <TableCell
                              className="table-row-header"
                              align="left"
                            >
                              Booking Start Date and Time
                            </TableCell>
                            <TableCell
                              className="table-row-header"
                              align="left"
                            >
                              Booking End Date and Time
                            </TableCell>
                            <TableCell
                              className="table-row-header"
                              align="left"
                            >
                              Booked By
                            </TableCell>
                            <TableCell
                              className="table-row-header"
                              align="left"
                            >
                              Slot No
                            </TableCell>
                            <TableCell
                              className="table-row-header"
                              align="left"
                            >
                              Check-In Time
                            </TableCell>
                            <TableCell
                              className="table-row-header"
                              align="left"
                            >
                              Check-out Time
                            </TableCell>
                            <TableCell
                              className="table-row-header"
                              align="left"
                            >
                              Status
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </TableContainer>

                    <Grid
                      container
                      xs={12}
                      style={{ justifyContent: "center" }}
                    >
                      {loading && <CircularProgress />}
                      {!loading && (
                        <h4>
                          {props.parked
                            ? "No Vehicles currently Parked"
                            : "No Booking Records Found Today"}
                        </h4>
                      )}
                    </Grid>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  dispatch,
});

export default withRouter(connect(null, mapDispatchToProps)(BookingsCard));
