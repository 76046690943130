// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import axios from "./axiosInterceptor";
import UrlConfig from "../Constants/apiUrls";
import { setCookie, getCookie } from "./cookies";
import TokenStorage from "./tokenstorage";

export async function getRefreshToken() {
  console.log('inside refreshtoken')
  await axios
    .post(
      UrlConfig('login'),
      {          
        realmName:(window.location.host === "localhost:3000" ||
        window.location.host === "dev-admin.parkzeus.in")?"Rlm_ROBOIN_Parking_Admin_DEV_IN":"Rlm_ROBOIN_Parking_Admin_IN",
        logInRequest:{
              refresh_token:getCookie('refresh'),
              grant_type:"refresh_token",
              client_id:getCookie("cliId"),
              client_secret:getCookie('cliSec')
 
            }
      },
      { "Content-Type": "application/json" }
    )
    .then((response) => {
      TokenStorage.storeToken(response.data.access_token);
      TokenStorage.storeRefreshToken(response.data.refresh_token);
      setCookie("inactive", false);
      let dt = new Date();
      dt.setSeconds(dt.getSeconds() + (response.data.expires_in - 60));
      TokenStorage.setTokenExpirationTime(dt);
    })
    .catch((error) => {
      window.location.href = "/login";
      console.log("error refreshing token refreshToken", error);
      if (error.response.status === 400) {
        if (error.response.data.error_description === "User disabled") {
          setCookie("inactive", true);
        } else {
          TokenStorage.clear();
          window.location.href = "/login";
        }
      }
    });
}
